
import { Component, Vue, Watch } from 'vue-property-decorator'
// import Multiselect from 'vue-multiselect'

import { getLoginReport } from '@/components/admin/users/users.service'
import { PaginationVM, VwLoginReport } from '@/models'
import moment from 'moment'

@Component({
  components: {
    // Multiselect
  }
})
export default class LoginReport extends Vue {
  public expandAllRows = false
  public formExpanded = false
  public showInsurances = false
  public confirmation = ''
  public fields = [
    {
      key: 'expand',
      label: ''
    },
    'index',
    // {
    //   key: 'userId',
    //   label: 'User ID'
    // },
    {
      key: 'username',
      label: 'Username',
      sortable: true
    },
    {
      key: 'firstName',
      label: 'First Name',
      sortable: true
    },
    {
      key: 'lastName',
      label: 'Last Name',
      sortable: true
    },
    {
      key: 'lastLogin',
      label: 'Last Login',
      sortable: true,
      formatter: 'formatDate'
    },
    {
      key: 'dayS_ACTIVITY',
      label: 'Days Activity',
      sortable: true
    }
  ]
  public items: VwLoginReport[] = []

  public perPage = 20
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public searchQuery = ''

  @Watch('perPage')
  perPageChange() {
    this.getLoginReport()
  }
  public async onPageChange(pageNumber: number) {
    this.expandAllRows = false
    await this.getLoginReport(pageNumber, this.searchQuery)
  }

  get _pagination() {
    return this.pagination
  }
  private async created() {
    await this.getLoginReport()
  }
  public async getLoginReport(pageNumber = 1, searchQuery = '') {
    const response = await getLoginReport(pageNumber, this.perPage, searchQuery)
    this.items = response.data
    this.pagination = JSON.parse(response.headers['x-pagination'])
  }

  public async filter() {
    await this.getLoginReport(1, this.searchQuery)
  }
  public async cleanFilter() {
    this.searchQuery = ''
    await this.getLoginReport(1, this.searchQuery)
  }

  //   public toggleRow() {
  //     this.items = this.items.map(c => {
  //       return { ...c, _showDetails: !c._showDetails }
  //     })
  //     this.expandAllRows = !this.expandAllRows
  //   }
  // private async _getDoctors(pageNumber = 1, searchQuery = '') {
  //   const response = await getDoctors(pageNumber, this.perPage, searchQuery)
  //   this.items = response.data
  //   this.pagination = JSON.parse(response.headers['x-pagination'])
  // }
  public formatDate(value: string) {
    if (value) return moment(value).format('MM/DD/YYYY HH:SS A')
  }
}
