import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { getNotifications } from '@/components/admin/users/users.service'
import SignalRServiceClass from '@/SignalRService'
import SignalRService from '@/SignalRService'
import { NotificationMessage } from '@/models'
@Module({ namespaced: true })
class AppModule extends VuexModule {
  public isLoading = false
  public closeModal = false
  public progress = 0
  public isUploading = false
  public messages: Notification[] = []
  public messageCount = 0
  public toastMessage: Notification = null
  public signalRService: SignalRService | null = null

  @Action
  initSignalR(userId: string) {
    this.context.commit('setSignalRService', userId)
  }
  @Action
  stopSignalR(userId: string) {
    this.context.commit('stopSignalRService', userId)
  }
  @Action
  public async sendSignalRMessage(payload: NotificationMessage) {
    if (this.signalRService) {
      // console.log(payload.userId, payload.message)
      await this.signalRService.sendMessage(payload)
    }
  }

  @Mutation
  setSignalRService(userId: string) {
    this.signalRService = new SignalRServiceClass(userId)
    this.signalRService.startConnection()
  }
  @Mutation
  stopSignalRService(userId: string) {
    this.signalRService.stopConnection()
  }

  @Mutation
  setToastMessage(message: Notification) {
    this.toastMessage = message
  }
  @Action
  addLoading(isLoading: boolean) {
    this.context.commit('setLoading', isLoading)
  }
  @Action
  addProgress(progress: number) {
    this.context.commit('setProgress', progress)
  }
  @Action
  addMessage(message: string) {
    this.context.commit('setMessage', message)
  }
  @Action
  async fetchNotifications(userId: string) {
    await getNotifications(userId).then(c => {
      this.context.commit('setNotifications', c.data)
    })
  }

  @Mutation
  setNotifications(notifications: Notification[]) {
    this.messages = notifications
  }

  @Mutation
  setLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }
  @Mutation
  setMessage(message: Notification) {
    this.messages.push(message)
  }

  @Mutation
  setProgress(progress: number) {
    this.progress = progress
    if (this.progress > 0 && this.progress < 100) {
      this.isUploading = true
    } else {
      this.isUploading = false
    }
  }
  @Action
  addCloseModal() {
    this.context.commit('setCloseModal')
  }

  @Mutation
  setCloseModal() {
    this.closeModal = true
  }

  get _closeModal() {
    return this.closeModal
  }
  get _messages() {
    return this.messages
  }
  get _isLoading() {
    return this.isLoading
  }
  get _progress() {
    return this.progress
  }
  get _isUploading() {
    return this.isUploading
  }
  get _messageCount() {
    return this.messages.length
  }
  get getSignalRService() {
    return this.signalRService
  }
}
export default AppModule
