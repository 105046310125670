
import { Menu, Notification } from '@/models'
import SignalRService from '@/SignalRService'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { markAsRead } from '../admin/users/users.service'

const AppModule = namespace('app')

@Component
export default class MenuBar extends Vue {
  @AppModule.Getter
  public _messageCount!: () => number
  @AppModule.Action('fetchNotifications') fetchNotifications!: (
    userId: string
  ) => Promise<void>
  @AppModule.Action('stopSignalR') stopSignalR!: () => Promise<void>
  // private signalRService = new SignalRService()

  @AppModule.Getter
  public _messages!: () => Notification[]
  public showNotificationCenter = false
  private messages = []
  @Prop() data!: Menu[]
  public currentDoctor: string | null = null
  public currentUser = ''
  public message = {
    title: null,
    body: null,
    notificationId: null
  }
  async logout() {
    this.stopSignalR()
    localStorage.clear()
    this.$router.push('Login')
  }

  public showModal(notification: Notification) {
    this.message.title = notification.messageTitle
    this.message.body = notification.messageBody
    this.message.notificationId = notification.notificationID
    this.$root.$emit('bv::show::modal', 'message-modal', '#btnShow')
  }
  get counter() {
    return this.messages.length
  }

  async created() {
    // const userId = 5
    this.currentDoctor = JSON.parse(
      JSON.parse(JSON.stringify(localStorage.getItem('currentDoctor')))
    )

    if (localStorage.getItem('currentUser')) {
      const u = localStorage.getItem('currentUser')
      this.currentUser = JSON.parse(u || '{}')
    }
  }
  public showNotifications() {
    this.showNotificationCenter = !this.showNotificationCenter
  }
  public async markAsRead(notificationId: number) {
    // console.log(notificationId)
    await markAsRead(notificationId)
    this.$root.$emit('bv::hide::modal', 'message-modal', '#btnShow')
    await this.fetchNotifications(this.currentUser.rmtUserId)
  }

  switchDoctor() {
    localStorage.removeItem('currentDoctor')
    localStorage.removeItem('selectedDoctor')
    localStorage.setItem('needsADoctor', 'true')
    this.$router.push({ name: 'DoctorSelection' })
  }
}
