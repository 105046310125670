
import {
  Diagnostic,
  PaginationVM,
  PatientInfo,
  RecordDiagnostic,
  Role,
  UpdateBillingSemester,
  UpdateDiagnosticStatus
} from '@/models'

import moment from 'moment'
import { Debounce } from 'typescript-debounce'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getCurrentUser, getUserRole } from '../admin/users/users.service'
import {
  deleteDiagnostic,
  deleteAllDiagnostics,
  getDiagnostics,
  updateDiagnosticStatus
} from '../diagnostics/diagnostics.service'
import {
  getPatientByRecord,
  getRecordDiagnostics,
  updateBillingSemester
} from '../patients/patients.service'
import vueShowMoreText from 'vue-show-more-text'
import PdfMake from 'pdfmake'

@Component({
  components: {
    vueShowMoreText
  }
})
export default class DX extends Vue {
  @Prop({ type: Object, default: () => ({}) }) selected!: Record<string, string>
  @Prop({ type: String, default: () => '' }) recordID!: string

  public patientInfo = {} as PatientInfo
  public note: string | null = null
  public recordDiagnostics: RecordDiagnostic[] = []
  public isLoading = false
  public fields = [
    {
      key: 'expand',
      label: '',
      tdClass: 'align-middle',
      exportable: false
    },
    {
      key: 'index',
      label: 'Idx',
      tdClass: 'align-middle',
      exportable: false
    },

    {
      key: 'diagnosticId',
      label: 'Code',
      tdClass: 'align-middle',
      order: 1,
      exportable: true
    },
    {
      key: 'descriptions',
      label: 'Description',
      tdClass: 'align-middle',
      order: 2,
      exportable: true
    },

    {
      key: 'cmsHcc1',
      label: 'CMSHcc1',
      tdClass: 'align-middle',
      order: 3,
      exportable: true
    },
    {
      key: 'cmsHcc2',
      label: 'CMSHcc2',
      tdClass: 'align-middle',
      order: 4,
      exportable: true
    },
    {
      key: 'rxHcc1',
      label: 'RxHcc1',
      tdClass: 'align-middle',
      order: 5,
      exportable: true
    },
    {
      key: 'crosswalkDx',
      label: 'CrosswalkDx',
      tdClass: 'align-middle',
      order: 5,
      exportable: false
    },

    // {
    //   key: "idInteraction",
    //   label: "ID Interaction",
    // },
    {
      key: 'treatment',
      label: 'Treatment',
      tdClass: 'align-middle',
      order: 7,
      exportable: false
    },
    // {
    //   key: 'documentation',
    //   label: 'Documentation',
    //   tdClass: 'align-middle',
    //   order: 8,
    //   exportable: false
    // },
    {
      key: 'clinical_Manifestations',
      label: 'Clinical Manifestations',
      tdClass: 'align-middle',
      order: 8,
      exportable: false
    },

    {
      key: 'alert',
      label: 'Alert',
      tdClass: 'align-middle',
      order: 10,
      exportable: false
    },
    {
      key: 'specialty',
      label: 'Specialty',
      tdClass: 'align-middle',
      order: 11,
      exportable: false
    },
    {
      key: 'provider',
      label: 'Provider',
      tdClass: 'align-middle',
      order: 12,
      exportable: false
    },
    {
      key: 'preview_PN_Flag',
      label: 'Previously Selected',
      tdClass: 'align-middle'
    },
    {
      key: 'pnFlag',
      label: 'Attach',
      tdClass: 'align-middle',
      exportable: false
    },
    {
      key: 'billingFSM',
      label: 'Billing First Semester',
      tdClass: 'align-middle',
      order: 13,
      exportable: true
    },
    {
      key: 'billingSSM',
      label: 'Billing Second Semester',
      tdClass: 'align-middle',
      order: 14,
      exportable: true
    },
    {
      key: 'actions',
      label: 'Active',
      tdClass: 'align-middle',
      order: 15,
      exportable: true
    },
    {
      key: 'deleteAction',
      label: '',
      tdClass: 'align-middle',
      exportable: false
    }
  ]
  public value: RecordDiagnostic[] = []
  //   public options: Diagnostic[] = []
  public addByList = false
  public diagnosticList = ''
  public tabIndex = 0
  public expandAllRows = false
  public perPage = 1000
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public expandedNotes = false
  public noteTitle = 'Doctor'
  public type = 1
  public isMAG = false
  public currentUser = ''
  public selectedSemester = 1
  // public showOptimizer = true
  public optimizerFlag = true
  public printMode = false
  public readOnly = false
  public showPrint = false
  public showDelete = false
  public exportable = false
  public billedDx: RecordDiagnostic[] = []
  @Watch('perPage')
  perPageChange() {
    this._getRecordDiagnostics()
  }

  public print() {
    console.log('Printing completed...')
    this.expandAllRows = true
    this.printMode = true
  }
  public checkServiceDate(date) {
    if (date) {
      const quarter = moment(new Date(date), 'DD/MM/YYYY').quarter()
      return
    }
  }

  public formatMoney(value) {
    return new Intl.NumberFormat('us-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value)
  }

  public async onPageChange(pageNumber: number) {
    this.expandAllRows = false
    await this._getRecordDiagnostics(pageNumber)
  }
  private async created() {
    this.currentUser = getCurrentUser()
    this.fillData()
  }
  get _pagination() {
    return this.pagination
  }

  public toggleRow() {
    this.recordDiagnostics = this.recordDiagnostics.map(c => {
      return { ...c, _showDetails: !c._showDetails }
    })
    this.expandAllRows = !this.expandAllRows
  }
  @Debounce({ millisecondsDelay: 2000 })
  private _getPatientByRecord(notify = false) {
    getPatientByRecord(this.recordID).then(r => {
      this.patientInfo = r[0]
      this.isLoading = false
      if (notify == true) {
        this.$swal.fire(
          `Success!`,
          `The diagnostic has been deleted`,
          'success'
        )
      }
      this.showPrint = true
      // this.exportable = true
    })
    // this.patientInfo = response[0]
    // console.log(this.patientInfo)
  }
  private async fillData() {
    const userRole = getUserRole()
    this.isMAG =
      userRole === Role.Superuser ||
      userRole === Role.Staff ||
      userRole === Role.Admin
    Promise.all([this._getPatientByRecord(), this._getRecordDiagnostics()])
    if (!this.isMAG) {
      this.optimizerFlag = false
    } else {
      this.showDelete = true
    }
    if (userRole === Role.InsuranceStaff) {
      this.readOnly = true
    }
    if (
      userRole === Role.Admin ||
      userRole === Role.Superuser ||
      userRole == Role.Staff
    ) {
      this.exportable = true
    } else {
      this.exportable = false
    }
  }
  public handleActivation(checked: boolean, item: RecordDiagnostic) {
    const obj: UpdateDiagnosticStatus = {
      recordID: this.recordID,
      diagnosticID: item.diagnosticId.trim(),
      statusValue: checked
    }
    updateDiagnosticStatus(obj).then(() => {
      this.fillData()
    })
  }
  public async deleteDiagnostic(diagnosticId: string, row: Diagnostic) {
    const currentQuarter = moment().quarter()
    const currentSemester = currentQuarter <= 2 ? 1 : 2

    const serviceDueQuarter = moment(
      new Date(row.serviceDate),
      'DD/MM/YYYY'
    ).quarter()
    const serviceDueSemester = serviceDueQuarter <= 2 ? 1 : 2

    const d = new Date()
    const currentYear = d.getFullYear()

    const serviceYear = new Date(row.serviceDate).getFullYear()

    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `This diagnostic will be deleted`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          deleteDiagnostic(this.recordID, diagnosticId, null, false).then(
            () => {
              this._getPatientByRecord(true)
              this.isLoading = false
              // this.$swal.fire(
              //   `Success!`,
              //   `The diagnostic has been deleted`,
              //   'success'
              // )
            }
          )

          const obj = this.recordDiagnostics.find(
            c => c.diagnosticId.trim() == diagnosticId.trim()
          )
          const idx = this.recordDiagnostics.findIndex(o => o === obj)
          this.recordDiagnostics.splice(idx, 1)
        } else {
          this.isLoading = false
        }
      })
    // this.$swal
    //   .fire(
    //     `Success!`,
    //     `The diagnostic has been deleted successfully`,
    //     'success'
    //   )
    //   .then((result): void => {
    //     deleteDiagnostic(
    //       this.recordID,
    //       diagnosticId,
    //       row.serviceDate,
    //       true
    //     ).then(() => {
    //       this._getPatientByRecord()
    //       const obj = this.recordDiagnostics.find(
    //         c => c.diagnosticId.trim() == diagnosticId.trim()
    //       )
    //       const idx = this.recordDiagnostics.findIndex(o => o === obj)
    //       this.recordDiagnostics.splice(idx, 1)
    //       this.isLoading = false
    //     })
    //   })
    // if (currentSemester === serviceDueSemester && currentYear === serviceYear) {
    //   this.$swal
    //     .fire({
    //       title: 'Are you sure?',
    //       text: `This code was billed during this current semester, and in order to not affect the calculation of RAF, it will be automatically removed after the impact period. By clicking delete, you agree to the automatic removal.`,
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Confirm'
    //     })
    //     .then((result): void => {
    //       if (result.isConfirmed) {
    //         deleteDiagnostic(
    //           this.recordID,
    //           diagnosticId,
    //           row.serviceDate,
    //           true
    //         ).then(() => {
    //           this._getPatientByRecord()
    //           this.isLoading = false
    //         })

    //         this.$swal.fire(
    //           `Success!`,
    //           `The diagnostic has been set to be deleted at the end of the semester`,
    //           'success'
    //         )
    //       }
    //     })
    // } else {
    //   this.isLoading = true

    //   this.$swal
    //     .fire({
    //       title: 'Are you sure?',
    //       text: `This diagnostic will be deleted`,
    //       icon: 'warning',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Confirm'
    //     })
    //     .then((result): void => {
    //       if (result.isConfirmed) {
    //         deleteDiagnostic(this.recordID, diagnosticId, null, false).then(
    //           () => {
    //             this._getPatientByRecord()
    //             this.isLoading = false
    //           }
    //         )

    //         const obj = this.recordDiagnostics.find(
    //           c => c.diagnosticId.trim() == diagnosticId.trim()
    //         )
    //         const idx = this.recordDiagnostics.findIndex(o => o === obj)
    //         this.recordDiagnostics.splice(idx, 1)
    //         this.$swal.fire(
    //           `Success!`,
    //           `The diagnostic has been deleted`,
    //           'success'
    //         )
    //       } else {
    //         this.isLoading = false
    //       }
    //     })
    // }
  }
  public async deleteAll() {
    this.isLoading = true

    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `All diagnostics will be deleted for this patient`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          deleteAllDiagnostics(this.recordID).then(() => {
            this._getPatientByRecord()
            this.recordDiagnostics = []
            this.isLoading = false
          })

          this.$swal.fire(
            `Success!`,
            `The diagnostics have been deleted`,
            'success'
          )
        } else {
          this.isLoading = false
        }
      })
  }

  private async _getRecordDiagnostics(pageNumber = 1) {
    const response = await getRecordDiagnostics(
      pageNumber,
      this.perPage,
      this.recordID
    )
    this.recordDiagnostics = response.data.map(c => {
      return { ...c, _showDetails: false }
    })
    this.pagination = JSON.parse(response.headers['x-pagination'])

    this.billedDx = this.recordDiagnostics.filter(c => c.pnFlag)
  }
  public formatDate(value: string) {
    return moment(value).format('MM/DD/YYYY')
  }
  //   public goBack() {
  //     this.$router.push({ name: 'PatientsView' })
  //   }

  //   @Debounce({ millisecondsDelay: 1000 })
  //   public _getDiagnostics(value: any): void {
  //     if (value.length >= 3) {
  //       getDiagnostics(1, 10, value).then(r => {
  //         this.options = r.data
  //       })
  //     }
  //   }
  //   public handleActivation(checked: boolean, item: RecordDiagnostic) {
  //     const obj: UpdateDiagnosticStatus = {
  //       recordID: this.recordID,
  //       diagnosticID: item.diagnosticId.trim(),
  //       statusValue: checked
  //     }
  //     updateDiagnosticStatus(obj).then(() => {
  //       this.fillData()
  //     })
  //   }
  public attach() {
    this.billedDx = this.recordDiagnostics.filter(c => c.pnFlag)
    this.$swal.fire({
      toast: true,
      position: 'bottom-end',
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast'
      },
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
      icon: 'success',
      text: `Selected ${this.billedDx.length}/12 Diagnostic/s`
    })
    if (this.billedDx.length > 12) {
      this.$swal.fire({
        title: 'Selection exceeded',
        text: `Please select up to 12 diagnostics`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      return
    }
  }
  public billing(checked: boolean, item: RecordDiagnostic, semester: number) {
    this.isLoading = true
    const obj: UpdateBillingSemester = {
      recordID: this.recordID,
      diagnosticID: item.diagnosticId.trim(),
      billing: checked,
      billingSemester: semester
    }
    updateBillingSemester(obj).then(c => {
      this._getPatientByRecord()
    })

    // console.log(this.recordDiagnostics)
    // console.log(this.options)
  }
  //   public async createNote() {
  //     const isValid = await this.$refs.observer.validate()
  //     if (!isValid) {
  //       return
  //     }
  //     if (this.type === 1) {
  //       await postDoctorNote(this.patientInfo.recordID, this.note)
  //     } else if (this.type === 2) {
  //       await postMAGNote(this.patientInfo.recordID, this.note)
  //     }
  //     await this._getPatientByRecord()
  //     this.note = null
  //     this.expandedNotes = false
  //   }
  //   public async autoCreate() {
  //     let diagnostics = this.value.map(v => v.diagnosticId.trim()).join(',')

  //     if (!this.diagnosticList) return
  //     diagnostics = this.diagnosticList.split('\n').join(',')

  //     if (!diagnostics) return
  //     const obj: UpdateBillingSemester = {
  //       recordID: this.recordID,
  //       diagnosticID: diagnostics,
  //       billing: true,
  //       billingSemester: this.selectedSemester
  //     }

  //     await updateBillingSemesterList(obj)
  //     await this._getPatientByRecord()
  //     await this._getRecordDiagnostics()

  //     this.$root.$emit('bv::toggle::modal', 'modal1', '#btnCreate')

  //   }
  //   public async create() {
  //     let diagnostics = this.value.map(v => v.diagnosticId.trim()).join(',')
  //     if (this.tabIndex === 1) {
  //       if (!this.diagnosticList) return
  //       diagnostics = this.diagnosticList.split('\n').join(',')
  //     }
  //     if (!diagnostics) return
  //     const obj: PostRecordDiagnostic = {
  //       recordID: this.recordID,
  //       diagnostics: diagnostics,
  //       optimizerFlag: this.optimizerFlag
  //     }

  //     await postRecordDiagnostic(obj)
  //     await this._getPatientByRecord()
  //     await this._getRecordDiagnostics()

  //   }
  @Watch('billedDx', { deep: true, immediate: true })
  onBilledChange(newVal: Record<string, string>) {
    // console.log(newVal)
    this.$emit('update:billed', newVal)
  }
  public change(showAll: boolean) {
    return showAll
  }
  public customLabel({
    diagnosticId,
    descriptions
  }: {
    diagnosticId: string
    descriptions: string
  }): string {
    return `${diagnosticId} - ${descriptions}`
  }
  public mounted() {
    this.$root.$on('hideModal', () => {
      this.$root.$emit('bv::hide::modal', 'modal', '#btnCreate')
      this.value = []
      this.diagnosticList = ''
      this._getRecordDiagnostics()
    })
  }
}
