
import { RecordDiagnostic, VisitOptions } from '@/models'
import { Debounce } from 'typescript-debounce'
import Multiselect from 'vue-multiselect'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { GetVisitOptions } from '../admin/forms.service'
import { getDiagnostics } from '../diagnostics/diagnostics.service'
import { extend, localize } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})

@Component({
  components: {
    Multiselect
  }
})
export default class Diagnostic extends Vue {
  @Prop({ type: Object, default: () => ({}) }) selected!: Record<string, string>
  @Prop({ type: String, default: () => '' }) sex: string
  @Prop({ type: String, default: () => '' }) serviceDate: string

  otherValues: Record<string, string> = {}
  localSelectedItems: Record<string, string> = {}
  showTextboxes: Record<string, boolean> = {}
  public showMedicationList = false
  public medicationRows = [
    { NameofMedication: null, Dose: null, Frequency: null, Reasonforuse: null }
  ]

  public response = null
  public currentPage = 1
  public itemsPerPage = 5
  public async mounted() {
    this.response = await GetVisitOptions(this.sex)
    this.$emit(
      'update:time',
      this.response.filter(
        c =>
          c.visitGroupDescription == 'TIME OF ENCOUNTER' ||
          c.visitGroupDescription == 'CPT CODE'
      )
    )
    this.$emit('update:data', this.response)
  }
  public value: RecordDiagnostic[] = []
  public options: Diagnostic[] = []
  public conditionalFields = {
    4: {
      show: false
    },
    5: {
      show: false
    },
    6: {
      show: false
    },
    7: {
      show: false
    },
    15: {
      show: false
    },
    // 19: {
    //   show: false
    // },
    68: {
      show: false
    }

    // 662: {
    //   show: true
    // },
    // 663: {
    //   show: true
    // },
    // 664: {
    //   show: true
    // },
    // 665: {
    //   show: true
    // },
    // 666: {
    //   show: true
    // },
    // 667: {
    //   show: false
    // },
    // 668: {
    //   show: false
    // },
    // 669: {
    //   show: false
    // },
    // 670: {
    //   show: true
    // }
  }

  @Debounce({ millisecondsDelay: 1000 })
  public _getDiagnostics(value: any): void {
    if (value.length >= 3) {
      getDiagnostics(1, 10, value).then(r => {
        this.options = r.data
      })
    }
  }
  getType(prop) {
    if (
      prop.trim() == 'AGE OF MENARCHE' ||
      prop.trim() == 'GRAVIDA' ||
      prop.trim() == 'ABORTION' ||
      prop.trim() == 'C-SECTION' ||
      prop.trim() == 'Intensity PAIN SCALE' ||
      prop.trim() == 'PARA'
    ) {
      return 'number'
    } else if (prop.trim() == 'LMP' || prop.trim() == 'DURATION') {
      return 'date'
    } else {
      return 'text'
    }
  }
  public isShown(groupId: string) {
    return this.conditionalFields[groupId]
      ? this.conditionalFields[groupId]['show']
      : true
  }
  public addRow() {
    this.medicationRows.push({
      NameofMedication: null,
      Dose: null,
      Frequency: null,
      Reasonforuse: null
    })
  }
  public removeRow(index) {
    this.medicationRows.splice(index, 1)
  }
  get groupedData() {
    let sortedResponse = this.response
    if (this.sex == 'M') {
      sortedResponse = sortedResponse
        ?.filter(c => c.m_ORDER != null)
        ?.sort((a, b) => a.m_ORDER - b.m_ORDER)
    } else {
      sortedResponse = sortedResponse
        ?.filter(c => c.f_ORDER != null)
        ?.sort((a, b) => a.f_ORDER - b.f_ORDER)
    }

    sortedResponse = sortedResponse?.map(option => {
      if (option.visitGroupID >= 3 && option.visitGroupID <= 16) {
        return { ...option, section: 'History of Present Illness (HPI)' }
      } else if (option.visitGroupID > 16 && option.visitGroupID <= 22) {
        return { ...option, section: 'Past Medical History (PMH)' }
      } else {
        return option // No section property added if condition is not met
      }
    })
    // console.log(sortedResponse)

    return sortedResponse?.reduce((acc: VisitOptions, obj: VisitOptions) => {
      // const key = obj.visitGroupDescription
      const headerKey = obj.header
      const groupKey = obj.visitGroupDescription
      obj.visible = true
      if (
        this.sex == 'M' &&
        (obj.visitGroupDescription.includes('PREGNANCY') ||
          obj.visitGroupDescription.includes('OB/GYN') ||
          obj.visitGroupDescription.includes('GENITALIA/FEMALE') ||
          obj.visitGroupDescription.includes('SERVICE DATE'))
      ) {
        obj.visible = false
      } else if (
        (this.sex == 'F' &&
          obj.visitGroupDescription.includes('GENITALIA/MALE')) ||
        obj.visitGroupDescription.includes('SERVICE DATE')
      ) {
        obj.visible = false
      }

      if (!acc[headerKey]) {
        acc[headerKey] = {}
      }

      if (!acc[headerKey][groupKey]) {
        acc[headerKey][groupKey] = []
      }

      acc[headerKey][groupKey].push(obj)

      return acc
    }, {})
  }
  processedName(name: string) {
    if (name) {
      const parts = name.split('_')
      return parts.length > 1 ? parts.slice(1).join('_') : name
    }
  }
  public customLabel({
    diagnosticId,
    descriptions
  }: {
    diagnosticId: string
    descriptions: string
  }): string {
    return `${diagnosticId} - ${descriptions}`
  }
  getPartAfterUnderscore(str) {
    return str.split('_')[1]
  }

  isOtherSelected(selected, model, group) {
    // console.log(selected)
    if (selected && typeof selected == 'string') {
      if (
        this.processedName(selected).toLowerCase() === 'medical list' ||
        this.processedName(selected).toLowerCase() === 'medication list'
      ) {
        this.localSelectedItems['medical list'] = this.medicationRows
      }
      if (
        this.processedName(selected).toLowerCase() === 'other' ||
        this.processedName(selected).toLowerCase() === 'comment'
      ) {
        return true
      } else {
        model[group] = ''
        delete model[group]
        return false
      }
    } else if (selected && typeof selected == 'object') {
      return selected
        .map(this.getPartAfterUnderscore)
        .some(
          res =>
            res.toLowerCase().startsWith('other') ||
            res.toLowerCase().startsWith('comment')
        )
    } else {
      return false
    }
  }
  get paginatedGroups() {
    const start = (this.currentPage - 1) * this.itemsPerPage
    const end = start + this.itemsPerPage
    return this.groupedData.slice(start, end)
  }
  @Watch('selected', { immediate: true, deep: true })
  onSelectedChange(newVal: Record<string, string>) {
    this.localSelectedItems = { ...newVal }
  }

  @Watch('medicationRows', { immediate: true, deep: true })
  onMedicationChange(newVal: Record<string, string>) {
    const allFieldsEmpty = this.areAllFieldsEmpty(newVal[0])
    let result = null
    if (!allFieldsEmpty) {
      result = newVal
    }
    // console.log(result)
    this.$emit('update:medication', result)
  }

  public areAllFieldsEmpty(obj) {
    return Object.values(obj).every(value => value === null || value === '')
  }
  @Watch('localSelectedItems', { deep: true, immediate: true })
  onLocalSelectedItemsChange(newVal: string) {
    // console.log(newVal)
    this.$emit('update:selected', newVal)
    // console.log(newVal)
    if (newVal && newVal['PAIN']) {
      const option = newVal['PAIN'].split('_')
      // const optionValue = option[0]
      const optionLabel = option[1]
      // console.log(optionLabel)
      if (optionLabel.toLowerCase() == 'yes') {
        // console.log('YES')
        this.conditionalFields[4].show = true
        this.conditionalFields[5].show = true
        this.conditionalFields[6].show = true
        this.conditionalFields[7].show = true
      } else {
        // console.log('NO')

        this.conditionalFields[4]['show'] = false
        this.conditionalFields[5]['show'] = false
        this.conditionalFields[6].show = false
        this.conditionalFields[7].show = false
      }
    }
    if (
      newVal &&
      newVal['MEDICATION'] &&
      typeof newVal['MEDICATION'] == 'string'
    ) {
      const option = newVal['MEDICATION'].split('_')
      const optionLabel = option[1]
      if (
        optionLabel.toLowerCase() == 'medical list' ||
        optionLabel.toLowerCase() == 'medication list'
      ) {
        this.showMedicationList = true
      } else {
        this.showMedicationList = false
      }
    }
    // console.log(this.showMedicationList)
  }
  @Watch('otherValues', { deep: true, immediate: true })
  onOtherValuesChange(newVal: Record<string, string>) {
    this.$emit('update:otherValues', newVal)
  }
  @Watch('value', { deep: true, immediate: true })
  onDiagnosticsChange(newVal: Record<string, string>) {
    this.$emit('update:diagnostics', newVal)
  }
}
