
import { Component, Vue } from 'vue-property-decorator'

import { namespace } from 'vuex-class'
import SignUp from './SignUp.vue'

const AppModule = namespace('app')

@Component({ components: { SignUp } })
export default class CompleteProfile extends Vue {
  public async mounted() {
    this.$refs['my-modal'].show()

    // this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
    //   console.log('Modal is about to be shown', bvEvent, modalId)
    // })
    console.log(1111)
  }
}
