
import { RecordDiagnostic, VisitOptions } from '@/models'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VueHtml2pdf from 'vue-html2pdf'

@Component({
  components: {
    VueHtml2pdf
  }
})
export default class Summary extends Vue {
  @Prop({ type: Object, default: () => ({}) }) selected!: Record<string, string>
  @Prop({ type: Object, default: () => ({}) }) otherValues!: Record<
    string,
    string
  >
  @Prop({ type: String, default: () => '' }) servicedate!: string
  @Prop({ type: String, default: () => '' }) cpt!: string
  @Prop({ type: Boolean, default: () => '' }) printMode: boolean

  @Prop({ type: Object, default: () => ({}) }) pninfo!: Record<string, string>
  @Prop({ type: Array, default: () => [] }) billed!: RecordDiagnostic[]
  @Prop({ type: Array, default: () => [] }) timeOptions!: VisitOptions[]
  @Prop({ type: Array, default: () => [] }) data!: VisitOptions[]

  public localData = null

  public otherAssessment = null
  public otherPlan = null

  public physical = [
    'ANUS AND RECTUM',
    'BREAST',
    'CHEST',
    'DIABETIC LEFT FOOT ULCER',
    'DIABETIC RIGHT FOOT ULCER',
    'DIABETIC ULCER',
    'EARS',
    'EXTREMITIES',
    'EYES',
    'GASTRO/ABDOMEN',
    'GENERAL APPEARANCE',
    'GENITALIA/MALE',
    'HEAD',
    'HEART',
    'LYMPHATIC AND MUSCULOSKELETAL',
    'MOUTH',
    'NECK',
    'NEUROLOGIC',
    'NO DIABETIC OR NON PRESSURE ULCER',
    'NOSE',
    'PRESSURE ULCER',
    'RESPIRATORY/LUNGS',
    'SKIN',
    'THROAT'
  ]
  public fields = [
    {
      key: 'diagnosticId',
      label: 'Code',
      tdClass: 'align-middle',
      order: 1,
      exportable: true
    },
    {
      key: 'descriptions',
      label: 'Description',
      tdClass: 'align-middle',
      order: 2,
      exportable: true
    },
    {
      key: 'clinical_Manifestations',
      label: 'Clinical Manifestations',
      tdClass: 'alignmiddle',
      order: 2,
      exportable: false
    },
    {
      key: 'treatment',
      label: 'Treatment',
      tdClass: 'align-middle',
      order: 7,
      exportable: false
    }
  ]
  public localSelected!: Record<string, string>

  public visitOptions = {}
  public clinicalinfo = {}
  public mounted() {
    this.localData = this.data
    // console.log(this.$el.outerHTML)

    this.$emit('summaryHtml', this.$el.outerHTML)
    this.localData = this.localData.reduce(
      (grouped: { [key: number]: VisitOptions[] }, option: VisitOptions) => {
        const age = option.visitOptionDescription
        option.visitOptionImage = null
        if (!grouped[age]) {
          grouped[age] = []
        }
        grouped[age].push(option)
        return grouped
      },
      {}
    )
    const visitOptionsMap: Record<string, string> = {}

    // console.log(this.getGroupedOptions['Vitals Signs'])

    Object.keys(this.getGroupedOptions['Vitals Signs']).forEach(group => {
      this.getGroupedOptions['Vitals Signs'][group].forEach(opt => {
        visitOptionsMap[opt.visitOptionDescription] = this.localSelected[
          opt.visitOptionDescription
        ]
      })
    })
    // Emit the key-value structure to the parent component
    this.$emit('vitalSigns', visitOptionsMap)
    this.$emit('chiefComplain', this.chiefComplain)
    // this.$emit('pdfRef', this.$refs['html2Pdf'])

    // console.log(this.$refs['html2Pdf'])
  }

  @Watch('selected', { immediate: true, deep: true })
  onSelectedChange(newVal: Record<string, string>) {
    // console.log(newVal)
    // console.log(newVal)
    this.localSelected = { ...newVal }
    this.$emit('update:summary', newVal)
  }
  // @Watch('localSelected', { immediate: true, deep: true })
  // onLocalSelectedChange(newVal: Record<string, string>) {
  //   console.log(newVal)
  //   // this.localSelected = { ...newVal }
  //   // this.$emit('update:summary', newVal)
  // }

  isOtherSelected(selected: string, model, group) {
    if (selected && typeof selected == 'string') {
      if (
        this.processedName(selected).toLowerCase() === 'other' ||
        this.processedName(selected).toLowerCase() === 'comment'
      ) {
        return true
      } else {
        model[group] = ''
        delete model[group]
        return false
      }
    } else if (selected && typeof selected == 'object') {
      return selected
        .map(this.getPartAfterUnderscore)
        .some(
          res =>
            res.toLowerCase().startsWith('other') ||
            res.toLowerCase().startsWith('comment')
        )
    } else {
      return false
    }
  }
  handleClinicalManifestation(newVal) {
    this.$emit('update:clinical', newVal)
  }
  handleTreatment(newVal) {
    this.$emit('update:treatment', newVal)
  }
  get filteredLocalSelected() {
    return value => {
      return Object.keys(this.localSelected).filter(category => {
        return !value.some(option => option.visitOptionDescription === category)
      })
    }
  }

  get groupedData() {
    return this.timeOptions?.reduce((acc: VisitOptions, obj: VisitOptions) => {
      const key = obj.visitGroupDescription
      obj.visible = true

      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }

  get getGroupedOptions() {
    const a = this.data?.reduce((acc: VisitOptions, obj: VisitOptions) => {
      // const key = obj.visitGroupDescription
      const headerKey = obj.header
      const groupKey = obj.visitGroupDescription
      obj.visible = true

      if (!acc[headerKey]) {
        acc[headerKey] = {}
      }

      if (!acc[headerKey][groupKey]) {
        acc[headerKey][groupKey] = []
      }

      acc[headerKey][groupKey].push(obj)

      return acc
    }, {})
    this.$emit('update:groupedSummary', a)

    return a
  }
  get vitalSigns() {
    return this.getGroupedOptions['Vitals Signs']
  }
  get physicalExam() {
    // return this.getGroupedOptions['Physical examination (PE)']
    const keysToKeep = ['Physical examination (PE)']
    const filteredData = Object.keys(this.getGroupedOptions)
      .filter(key => keysToKeep.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.getGroupedOptions[key]
        return obj
      }, {})
    return filteredData
  }
  get chiefComplain() {
    const keysToKeep = ['Vitals Signs', 'Physical examination (PE)', 'null']
    const filteredData = Object.keys(this.getGroupedOptions)
      .filter(key => !keysToKeep.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.getGroupedOptions[key]
        return obj
      }, {})
    return filteredData //this.getGroupedOptions['Chief Complaint']
  }

  processData(name: string, category) {
    if (typeof name == 'object') {
      const results = name
        .map(this.getPartAfterUnderscore)
        .map(i =>
          i === 'OTHER' || i === 'COMMENT' ? this.otherValues[category] : i
        )

      return results.join(', ')
    }
    if (
      this.processedName(name)?.toLowerCase() === 'other' ||
      this.processedName(name)?.toLowerCase() === 'comment'
    ) {
      this.localSelected[category] = this.otherValues[category]
      return this.localSelected[category]
    } else {
      return this.processedName(name)
    }
  }
  getPartAfterUnderscore(str) {
    return str.split('_')[1]
  }

  processedName(name: string) {
    if (name && typeof name == 'string') {
      const parts = name.split('_')
      return parts.length > 1 ? parts.slice(1).join('_') : name
    }
  }
  getGender(value: string) {
    return value == 'M' ? 'Male' : 'Female'
  }
  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0') // Get day and pad it to 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0') // Get month (0-indexed, so add 1)
    const year = date.getFullYear() // Get full year

    let hours = date.getHours() // Get hours
    const minutes = String(date.getMinutes()).padStart(2, '0') // Get minutes
    const seconds = String(date.getSeconds()).padStart(2, '0') // Get seconds

    const ampm = hours >= 12 ? 'PM' : 'AM' // Determine AM/PM
    hours = hours % 12 || 12 // Convert to 12-hour format (0 becomes 12)

    hours = String(hours).padStart(2, '0') // Pad hours to 2 digits

    return `${month}-${day}-${year} ${hours}:${minutes}:${seconds} ${ampm}`
  }
}
