
import { getGeneralReport } from '@/components/insurances/insurance.service'
import { PaginationVM, Role } from '@/models'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getCurrentUser, getUserRole } from '../admin/users/users.service'
import moment from 'moment'
import xlsx from 'json-as-xlsx'

@Component
export default class InsuranceGeneralReport extends Vue {
  public fields = [
    {
      key: 'index'
    },
    {
      key: 'documentID',
      label: 'NPI',
      sortable: true,
      order: 1
    },
    {
      key: 'doctor',
      label: 'Doctor',
      sortable: true,
      order: 2
    },

    {
      key: 'speciality',
      label: 'Speciality',
      sortable: true,
      order: 3
    },
    {
      key: 'insuranceName',
      label: 'Insurance Name',
      sortable: true,
      order: 4
    },
    {
      key: 'patientActives',
      label: 'Active Patients',
      sortable: true,
      order: 5
    },
    {
      key: 'rafScore',
      label: 'RAF Score',
      sortable: true,
      order: 6
    },
    {
      key: 'valueOfPremium',
      label: 'Value of Premium',
      sortable: true,
      order: 7
    },
    {
      key: 'expensesOverall',
      label: 'Expenses Overall',
      sortable: true,
      order: 8
    },
    {
      key: 'surplusAgreement',
      label: 'Surplus Agreement',
      sortable: true,
      order: 9
    },
    // {
    //   key: 'totalRafnum',
    //   label: 'Total RAFnum'
    // },
    {
      key: 'annualBudget',
      label: 'Annual Budget',
      sortable: true,
      order: 10
    },
    {
      key: 'mrl',
      label: 'MLR',
      sortable: true,
      order: 11
    },
    {
      key: 'surplusTotal',
      label: 'Surplus Total',
      sortable: true,
      order: 12
    },
    {
      key: 'netSurplus',
      label: 'Net Surplus',
      sortable: true,
      order: 13
    }
  ]
  public items = []
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public searchQuery = ''
  public currentUser = ''
  public showSearch = false
  public async created() {
    if (getUserRole() == Role.Doctor || getUserRole() == Role.Staff) {
      this.fields = this.fields.filter(
        c => c.key != 'doctor' && c.key != 'speciality' && c.key != 'documentID'
      )
      this.currentUser = getCurrentUser()

      this.showSearch = false
    } else {
      this.showSearch = true
    }
    await this._getGeneralReport()
  }

  @Watch('perPage')
  perPageChange() {
    this._getGeneralReport()
  }
  get _pagination() {
    return this.pagination
  }
  public async onPageChange(pageNumber: number) {
    await this._getGeneralReport(pageNumber, this.searchQuery)
  }
  public async filter() {
    await this._getGeneralReport(1, this.searchQuery)
  }
  public async cleanFilter() {
    this.searchQuery = ''
    await this._getGeneralReport(1, this.searchQuery)
  }
  public async _getGeneralReport(pageNumber = 1, searchQuery = '') {
    const res = await getGeneralReport(pageNumber, this.perPage, searchQuery)
    this.items = res.data
    this.pagination = JSON.parse(res.headers['x-pagination'])
  }
  public async exportTable() {
    let items = []
    const response = await getGeneralReport(1, 999999, this.searchQuery)
    items = response.data

    const updatedArray = items.map(obj => {
      const newObj = {}
      // Sort the first array based on the order property
      this.fields.sort((a, b) => a.order - b.order)
      // Get the keys in the order specified by the first array
      const keys = this.fields.map(x => x.key).filter(c => c != 'index')
      // Sort the keys of the object
      keys.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const label = this.fields.find(x => x.key === key).label
          newObj[label.toLowerCase()] = obj[key.toLowerCase()]
        }
      })
      return newObj
    })

    const data = [
      {
        sheet: 'Insurance General Report',
        columns: [],
        content: []
      }
    ]

    data[0].columns = this.fields
      .map(c => {
        return {
          label: c.label,
          value: c.key
        }
      })
      .filter(c => c.value != 'index')

    // console.log(this.currentUser)
    if (getUserRole() === Role.Doctor) {
      // alert(1)
      data[0].columns = [
        { label: 'NPI', value: 'documentID' },
        { label: 'Doctor Name', value: 'doctor' }
      ].concat(data[0].columns)
    }
    data[0].content = items.map(c => {
      return {
        ...c,
        valueOfPremium: '$' + c.valueOfPremium.toFixed(2),
        expensesOverall: this.formatMoney(c.expensesOverall),
        surplusAgreement: (c.surplusAgreement * 100).toFixed(2) + '%',
        annualBudget: this.formatMoney(c.annualBudget),
        mrl: (c.mrl * 100).toFixed(2) + '%',
        surplusTotal: this.formatMoney(c.surplusTotal),
        netSurplus: this.formatMoney(c.netSurplus)
      }
    })

    const settings = {
      fileName: 'Insurance General Report', // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      RTL: false // Display the columns from right-to-left (the default value is false)
    }
    xlsx(data, settings) // Will download the excel file
  }
  public formatMoney(value) {
    return new Intl.NumberFormat('us-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value)
  }
  public formatDate(value: string) {
    if (value) return moment(value).format('MM/DD/YYYY')
  }
}
