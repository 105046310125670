
import { Component, Vue, Watch } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'
import { getResolve } from '@/components/doctors/doctors.service'

import {
  getLoginReport,
  getUsers,
  sendNotification
} from '@/components/admin/users/users.service'
import {
  NotificationMessage,
  PaginationVM,
  PostNotification,
  SelectVM,
  VwLoginReport
} from '@/models'
import moment from 'moment'
import { namespace } from 'vuex-class'
const AppModule = namespace('app')

@Component({
  components: {
    Multiselect
  }
})
export default class NotificationsView extends Vue {
  @AppModule.Action('sendSignalRMessage') sendSignalRMessage!: (
    message: NotificationMessage
  ) => Promise<void>

  public expandAllRows = false
  public formExpanded = false
  public showInsurances = false
  public confirmation = ''
  public selected = 2
  public message = null
  public title = null
  public options = [
    { text: 'Roles', value: 1 },
    { text: 'Users', value: 2 }
  ]
  public fields = [
    {
      key: 'expand',
      label: ''
    },
    'index',
    {
      key: 'username',
      label: 'Username',
      sortable: true
    },
    {
      key: 'firstName',
      label: 'First Name',
      sortable: true
    },
    {
      key: 'lastName',
      label: 'Last Name',
      sortable: true
    },
    {
      key: 'lastLogin',
      label: 'Last Login',
      sortable: true,
      formatter: 'formatDate'
    },
    {
      key: 'dayS_ACTIVITY',
      label: 'Days Activity',
      sortable: true
    }
  ]
  public items: VwLoginReport[] = []

  public perPage = 20
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public searchQuery = ''
  public roles: SelectVM[] = []
  public users: SelectVM[] = []

  public selectedRoles: SelectVM[] = []
  public selectedUsers: SelectVM[] = []

  @Watch('perPage')
  perPageChange() {
    this.getLoginReport()
  }
  public async onPageChange(pageNumber: number) {
    this.expandAllRows = false
    await this.getLoginReport(pageNumber, this.searchQuery)
  }
  public async onTypeChange(e: any) {
    if (e == 1) {
      this.roles = (await getResolve()).roles
    } else {
      const response = await getUsers(1, 5000)
      this.users = response.data.map(c => ({
        value: c.userId.toString(),
        label: c.documentID + ' - ' + c.fullName
      }))
    }
  }

  get _pagination() {
    return this.pagination
  }
  private async created() {
    const response = await getUsers(1, 5000)
    this.users = response.data.map(c => ({
      value: c.rmT_UserId.toString(),
      label: c.documentID + ' - ' + c.fullName
    }))
  }
  public async getLoginReport(pageNumber = 1, searchQuery = '') {
    const response = await getLoginReport(pageNumber, this.perPage, searchQuery)
    this.items = response.data
    this.pagination = JSON.parse(response.headers['x-pagination'])
  }

  public async filter() {
    await this.getLoginReport(1, this.searchQuery)
  }
  public async cleanFilter() {
    this.searchQuery = ''
    await this.getLoginReport(1, this.searchQuery)
  }
  public async sendNotification() {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) return false
    const model: PostNotification = {
      MessageBody: this.message,
      MessageTitle: this.title,
      Roles: this.selectedRoles.flatMap(c => c.label).join(','),
      UserId_M: this.selectedUsers.flatMap(c => c.value).join(',')
    }
    await sendNotification(model)

    const notificationMessage: NotificationMessage = {
      ...model,
      UserId: model.UserId_M,
      ByRole: this.selected == 1
    }
    this.$bvToast.toast('Action submitted successfully', {
      title: 'Success',
      variant: 'success',
      solid: true,
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: 5000,
      appendToast: true
    })
    await this.sendSignalRMessage(notificationMessage)
  }

  public formatDate(value: string) {
    if (value) return moment(value).format('MM/DD/YYYY HH:SS A')
  }
}
