// eslint-disable-next-line @typescript-eslint/camelcase
import {
  FormsResolve,
  FormVM,
  PostFormResult,
  MagForm,
  MagHomeForm,
  Role,
  PNInformationResolve,
  VisitOptions,
  PostTempDX,
  MedicalRecord
} from '@/models'
import axios, { AxiosResponse } from 'axios'
const API_URL = 'Forms'
import { getUserRole } from '@/components/admin/users/users.service'
export const getResolve = async (): Promise<FormsResolve> => {
  const response = await axios.get(API_URL + `/FormsResolve`)
  return response.data
}
export const postForm = async (model: FormVM): Promise<PostFormResult[]> => {
  const { data } = await axios.post(API_URL, model)
  return data
}
export const _getForms = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
): Promise<AxiosResponse<MagForm[]>> => {
  let doctorId: string | null = null
  if (getUserRole() == Role.Staff) {
    doctorId = localStorage.getItem('selectedDoctor')
  }
  let url = `/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  if (doctorId) {
    url += `&doctorId=${doctorId}`
  }
  const response = await axios.get(API_URL + url)
  return response || null
}
export const _getHomeForms = async (
  doctorId: number | null = null,
  pageNumber = 1,
  pageSize = 1000
): Promise<AxiosResponse<MagHomeForm[]>> => {
  let params = `pageNumber=${pageNumber}&pageSize=${pageSize}`

  if (doctorId) {
    params += `&doctorId=${doctorId}`
  }

  const response = await axios.get(API_URL + `/GetHomeForms?${params}`)
  return response || null
}
export const updateForm = async (model: FormVM): Promise<FormVM[]> => {
  const { data } = await axios.put<FormVM[]>(API_URL + `/UpdateForm`, model)
  return data
}

export const GetPNInformation = async (
  doctorId: number,
  patientDocumentId: string
): Promise<PNInformationResolve> => {
  const response = await axios.get(
    API_URL +
      `/GetPNInformation?doctorID=${doctorId}&patientDocumentID=${patientDocumentId}`
  )
  return response.data
}

export const GetVisitOptions = async (
  gender: string
): Promise<VisitOptions[]> => {
  const response = await axios.get(
    API_URL + `/GetVisitOptions?gender=${gender}`
  )
  return response.data
}
export const GetCPT = async (): Promise<VisitOptions[]> => {
  const response = await axios.get(API_URL + `/GetCPT`)
  return response.data
}
export const postTempDX = async (
  model: PostTempDX
): Promise<PostFormResult[]> => {
  const { data } = await axios.post(API_URL + `/PostTempDX`, model)
  return data
}

export const PostSmartPN = async (
  model: MedicalRecord
): Promise<PostFormResult[]> => {
  const { data } = await axios.post(
    API_URL +
      `/PostSmartPN
`,
    model
  )
  return data
}
