
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { getDoctorInfo } from '../doctors/doctors.service'
import { PNInformationResolve } from '@/models'
import { GetCPT } from '../admin/forms.service'
@Component
export default class PNInformation extends Vue {
  @Prop() public record!: string

  public PNInformationResolve = {} as PNInformationResolve
  public selected = ''
  public selectedCPT = null
  public formatted = null
  public cptOptions = null
  public showOtherCPT = false
  public otherCPT = null
  public dataAvailable = false
  public options = [
    { text: 'Male', value: 'M' },
    { text: 'Female', value: 'F' }
  ]
  public form = {
    age: '',
    serviceDate: '',
    gender: ''
  }

  dateFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
  minDate: string = this.computeMinDate()
  maxDate: Date = new Date()

  public async mounted() {
    this.cptOptions = await GetCPT()
  }
  @Watch('selectedCPT', { immediate: true })
  onSelectedChange(newVal: number) {
    if (newVal === 660) {
      this.showOtherCPT = true
      this.$emit('update:cpt', newVal)
    } else {
      this.showOtherCPT = false
      const val = this.cptOptions?.filter(c => c.visitOptionID === newVal)[0]

      this.$emit('update:cpt', val)
    }
  }
  @Watch('otherCPT', { immediate: true, deep: true })
  onOtherCPT(newVal: number) {
    if (this.selectedCPT === 660) {
      this.$emit('update:cpt', this.otherCPT)
    }
  }

  public computeMinDate(): string {
    const today = new Date()
    const pastDate = new Date(today.setDate(today.getDate() - 90))
    return pastDate.toISOString().split('T')[0]
  }
  public onContext(ctx) {
    this.formatted = moment(ctx).format('MM-DD-YYYY')
  }
  public onInput(ctx) {
    const a = moment(ctx).toDate()
    if (a.toString() != 'Invalid Date') this.form.serviceDate = a.toDateString()
  }
  public async created() {
    this.PNInformationResolve = await getDoctorInfo(this.record)
    this.dataAvailable = true
    this.selected = this.PNInformationResolve.patientInfo.sex.toString()
    this.form.age = this.PNInformationResolve?.patientInfo.age
    this.$emit('update:pninfo', this.PNInformationResolve)
  }
  @Watch('formatted', { deep: true, immediate: true })
  onLocalSelectedItemsChange(newVal: Record<string, string>) {
    this.$emit('update:servicedate', newVal)
  }
}
