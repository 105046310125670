import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import interceptor from '@/http-interceptor'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//Axios
import axios from 'axios'
import VueAxios from 'vue-axios'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import VueTheMask from 'vue-the-mask'
import VTooltip from 'v-tooltip'
import VueHtmlToPaper from 'vue-html-to-paper'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import IdleVue from 'idle-vue'
import PdfMake from 'pdfmake'
import excel from 'vue-excel-export'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies, { expires: '30d' })
Vue.use(VueExcelXlsx)
Vue.use(excel)
import {
  required,
  email,
  numeric,
  max,
  min,
  confirmed,
  // eslint-disable-next-line @typescript-eslint/camelcase
  min_value,
  // eslint-disable-next-line @typescript-eslint/camelcase
  max_value
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import VueXlsx from 'vue-js-xlsx'
import JsonExcel from 'vue-json-excel'
// import msal from 'vue-msal'
import { default as msalPlugin } from 'vue-msal-browser'
const msalConfig = {
  auth: {
    clientId: '4a5785f3-5026-40e1-98ae-85d38c541c0d',
    authority: 'https://login.microsoftonline.com/consumers',
    redirectUri: 'https://www.magdashboard.com', // It has to be configured on your Azure tenant
    scopes: ['user.read']
  },

  mode: 'popup'
}
Vue.use(msalPlugin, msalConfig)

Vue.component('downloadExcel', JsonExcel)
const phoneRule = {
  getMessage(field, args) {
    return `The ${field} must be either a valid phone number or e-mail`
  },
  validate(value, args) {
    // Custom regex for a phone number
    // (supplied in the SO post: https://stackoverflow.com/q/50033651/2600825)
    const MOBILEREG = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/

    // Check for either of these to return true
    return MOBILEREG.test(value)
  }
}

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 900000, // 3 seconds
  startAtIdle: false
})
Vue.use(VTooltip)

Vue.use(VueXlsx)
Vue.use(VueTheMask)
Vue.use(PdfMake)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
// No message specified.
extend('phone', phoneRule)
extend('email', email)
extend('required', required)
extend('numeric', numeric)
extend('max', max)
extend('min', min)
extend('min_value', min_value)
extend('max_value', max_value)
extend('confirmed', confirmed)

// extend('url', url)
extend('url', {
  validate(value: string | null | undefined): boolean {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      )
    }

    return false
  },
  message: 'This value must be a valid URL'
})
Vue.use(VueSweetalert2)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Vue.use(moment)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

async function init() {
  const vue = new Vue({
    router,
    store,
    render: h => h(App)
  })
  fetch(process.env.BASE_URL + 'config2.txt')
    .then(res => res.json())
    .then(data => {
      axios.defaults.baseURL = data.API_URL
      vue.$mount('#app')
    })
  interceptor(vue)
}

init()
