import axios, { AxiosResponse } from 'axios'
import Vue from 'vue'
import store from './store'

export default async function interceptor(context: Vue) {
  const vm = context
  const statusCode = Object.freeze({
    Unauthorized: 401,
    BadRequest: 400,
    OK: 200,
    NoContent: 204,
    LoginTimeout: 440,
    UnderMaintenance: 503
  })
  axios.interceptors.request.use(
    (config: any) => {
      store.dispatch('app/addLoading', true)
      return config
    },
    (error: any) => {
      setTimeout(() => {
        store.dispatch('app/addLoading', false)
      }, 300)
      return Promise.reject(error)
    }
  )

  axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  const showToast = (msj: string, title: string, variant: string) => {
    vm.$bvToast.toast(msj, {
      title: title,
      variant: variant,
      solid: true,
      toaster: 'b-toaster-bottom-right',
      autoHideDelay: 6000,
      appendToast: true
    })
  }
  const successHandler = (response: AxiosResponse) => {
    // console.log(response)
    switch (response.status) {
      case statusCode.NoContent:
        showToast('Action submitted successfully', 'Success!', 'success')
        break
      case statusCode.OK:
        if (Array.isArray(response.data))
          response.data.forEach(element => {
            // console.log(element)
            // console.log(element.error)
            if (element.error) {
              showToast(element.error, 'Error!', 'danger')
            } else if (element.result === 1 && !element.error) {
              showToast('Action submitted successfully', 'Success!', 'success')
            } else if (
              element.errorMessage?.includes('success') &&
              element.errorMessage != ' '
            ) {
              showToast(element.errorMessage, 'Success!', 'success')
            } else if (
              element.errorMessage?.includes('does not exist') &&
              element.errorMessage != ' '
            ) {
              showToast(element.errorMessage, 'Error!', 'danger')
            } else if (
              element.errorMessage?.includes('already exist') &&
              element.errorMessage != ' '
            ) {
              showToast(element.errorMessage, 'Warning!', 'warning')
            } else if (
              element.errorMessage?.includes('already') &&
              element.errorMessage != ' '
            ) {
              showToast(element.errorMessage, 'Error!', 'danger')
            }
          })
        break
    }

    setTimeout(() => {
      store.dispatch('app/addLoading', false)
    }, 300)
    return response
  }
  const errorHandler = async (error: any) => {
    if (!error.response) return
    switch (error.response.status) {
      case statusCode.BadRequest:
        error.response.data.errors.forEach((err: string) => {
          // store.dispatch("app/addCloseModal", true);

          if (err != ' ' && !err.includes('success')) {
            showToast(err, 'Error!', 'danger')
          }
          if (err.includes('success')) {
            context.$root.$emit('hideModal')
            showToast(`${err}`, 'Success!', 'success')
            // const obj = { ...error.response, statusCode: statusCode.NoContent };
            // return obj;
          }
        })
        break
      case statusCode.Unauthorized:
        vm.$router.push({ name: 'Login' })
        break
      case statusCode.UnderMaintenance:
        vm.$router.push({ name: 'UnderMaintenance' })
    }
    // const originalRequest = error.config;
    // switch (error.response?.status) {
    //   case statusCode.Unauthorized:
    //     if(JSON.parse(error.response?.headers["token-expired"]) && !originalRequest._retry) {
    //       originalRequest._retry = true;
    //       const _userInfo = await store.dispatch("auth/refreshToken", { token: localStorage.getItem("token"), tokenKey: localStorage.getItem("tokenKey") });
    //       error.config.headers.Authorization = 'Bearer ' + _userInfo.token;

    //       return axios(originalRequest);
    //     } else {
    //       await store.dispatch("auth/logOut");
    //       vm.$router.push({ name: "Login" });
    //       showToast('Usted no tiene permiso para ingresar.', '¡Prohibido!', 'danger');
    //     }

    //     break;

    //   case statusCode.BadRequest:
    //     error.response.data.errors.forEach((err: string) => {
    //       showToast(err, '¡Error!', 'danger')
    //     });

    //     break;
    //     case statusCode.LoginTimeout:
    //       store.dispatch("auth/addLogout");
    //       vm.$router.push({ name: "Login" });
    //       showToast('Sesión vencida.', 'Error!', 'danger');

    //       break;
    // }

    setTimeout(() => {
      store.dispatch('app/addLoading', false)
    }, 300)
    return Promise.reject(error)
  }

  axios.interceptors.response.use(
    (response: any) => successHandler(response),
    (error: any) => errorHandler(error)
  )
}
