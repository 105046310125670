
import { Component, Vue, Watch } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'

import {
  updateDoctorStatus,
  getDoctors
} from '@/components/doctors/doctors.service'
import { PaginationVM, Doctor, SelectVM, UpdateEntityStatus } from '@/models'
import moment from 'moment'

@Component({
  components: {
    Multiselect
  }
})
export default class DoctorsView extends Vue {
  public expandAllRows = false
  public formExpanded = false
  public fields = [
    {
      key: 'expand',
      label: ''
    },
    {
      key: 'documentId',
      label: 'NPI'
    },
    {
      key: 'fullName',
      label: 'Doctor'
    },
    {
      key: 'speciality',
      label: 'Speciality'
    },

    {
      key: 'dob',
      label: 'DOB',
      formatter: 'formatDate'
    },
    {
      key: 'age',
      label: 'Age'
    },
    {
      key: 'sex',
      label: 'Sex'
    },
    {
      key: 'phone',
      label: 'Phone 1'
    },
    {
      key: 'mobile',
      label: 'Phone 2'
    },

    {
      key: 'doctorStatus',
      label: 'Status'
    },

    {
      key: 'show_details',
      label: ''
    }
  ]
  public items: Doctor[] = []

  public insurances: SelectVM[] = []
  public insurancesPlans: SelectVM[] = []
  public selectedDoctor: SelectVM | null = null
  public doctorList: SelectVM[] = []

  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public searchQuery = ''
  @Watch('perPage')
  perPageChange() {
    this._getDoctors()
  }
  public async onPageChange(pageNumber: number) {
    this.expandAllRows = false
    await this._getDoctors(pageNumber, this.searchQuery)
  }

  get _pagination() {
    return this.pagination
  }
  private async created() {
    await this._getDoctors()
  }

  public async filter() {
    await this._getDoctors(1, this.searchQuery)
  }
  public async cleanFilter() {
    this.searchQuery = ''
    await this._getDoctors(1, this.searchQuery)
  }

  public toggleRow() {
    this.items = this.items.map(c => {
      return { ...c, _showDetails: !c._showDetails }
    })
    this.expandAllRows = !this.expandAllRows
  }
  private async _getDoctors(pageNumber = 1, searchQuery = '') {
    const response = await getDoctors(pageNumber, this.perPage, searchQuery)
    this.items = response.data
    this.pagination = JSON.parse(response.headers['x-pagination'])
  }
  public formatDate(value: string) {
    if (value) return moment(value).format('MM/DD/YYYY')
  }
  public getAge(value: string) {
    if (value) return moment().diff(value, 'years')
  }

  public changeStatus(checked: boolean, item: Doctor) {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `This doctor status will be set as ${
          checked ? 'Active' : 'Inactive'
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          const obj: UpdateEntityStatus = {
            documentID: item.documentId,
            statusValue: checked
          }

          updateDoctorStatus(obj)
          this.$swal.fire(
            `${checked ? 'Activated' : 'Deactivated'}!`,
            `The doctor has been ${
              checked ? 'Activated' : 'Deactivated'
            } successfully`,
            'success'
          )
        } else {
          item.doctorStatus = !item.doctorStatus
        }
      })
  }
}
