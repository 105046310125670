
import { Component, Vue, Watch } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'

import {
  getResolve,
  postPatient,
  updatePatientStatus,
  updatePatient,
  getPatientsList
} from '@/components/patients/patients.service'
import { getResolve as Doc } from '@/components/admin/forms.service'
import {
  PaginationVM,
  Patient,
  PatientList,
  PatientVM,
  Role,
  SelectVM,
  UpdatePatientStatus
} from '@/models'
import moment from 'moment'
import {
  getCurrentUser,
  getUserRole
} from '@/components/admin/users/users.service'

@Component({
  components: {
    Multiselect
  }
})
export default class PatientsView extends Vue {
  public expandAllRows = false
  public formExpanded = false
  public fields = [
    {
      key: 'expand',
      label: ''
    },
    {
      key: 'index',
      label: 'Index'
    },
    // {
    //   key: 'documentID',
    //   label: 'NPI',
    //   sortable: true
    // },
    {
      key: 'doctor',
      label: 'Doctor',
      sortable: true
    },
    {
      key: 'speciality',
      label: 'Speciality',
      sortable: true
    },

    {
      key: 'patient',
      label: 'Patient Name',
      sortable: true
    },
    {
      key: 'insuranceContractNo',
      label: 'Ins Contract No',
      sortable: true
    },
    {
      key: 'insuranceName',
      label: 'Insurance',
      sortable: true
    },

    {
      key: 'dob',
      label: 'DOB',
      formatter: 'formatDate',
      sortable: true
    },
    {
      key: 'age',
      label: 'Age',
      sortable: true
    },
    {
      key: 'sex',
      label: 'Sex',
      sortable: true
    },
    {
      key: 'phone',
      label: 'Phone 1',
      sortable: true
    },
    // {
    //   key: 'mobile',
    //   label: 'Phone 2'
    // },

    {
      key: 'patientStatus',
      label: 'Status',
      sortable: true
    },

    {
      key: 'show_details',
      label: ''
    }
  ]
  public items: PatientList[] = []

  public insurances: SelectVM[] = []
  public insurancesPlans: SelectVM[] = []
  public selectedDoctor: SelectVM | null = null
  public doctorList: SelectVM[] = []
  public genders: SelectVM[] = []
  public searchQuery = ''
  public form = {} as PatientVM
  public isEditing = false
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public currentUser = ''
  public searchLabel = '(Dr. Record ID, Patient Name or INS Contract No):'
  public showDoctorSelection = false
  public formatted = ''

  @Watch('perPage')
  perPageChange() {
    this._getPatients()
  }
  public getCustomLabel(e: any) {
    return `${e.documentId} - ${e.label}`
  }
  public async onPageChange(pageNumber: number) {
    this.expandAllRows = false
    await this._getPatients(pageNumber, this.searchQuery)
  }
  public onContext(ctx) {
    this.formatted = moment(ctx).format('MM/DD/YYYY')
  }
  public onInput(ctx) {
    const a = moment(ctx).toDate()
    if (a.toString() != 'Invalid Date') this.form.dob = a
  }
  get _pagination() {
    return this.pagination
  }
  private async created() {
    this.currentUser = getCurrentUser()
    if (getUserRole() == Role.Doctor || getUserRole() == Role.Staff) {
      this.fields = this.fields.filter(
        c => c.key != 'doctor' && c.key != 'speciality'
      )
    } else {
      this.searchLabel = '(Doctor Name, NPI, Patient Name or INS Contract No):'
      this.fields.splice(2, 0, {
        key: 'npi',
        label: 'NPI',
        sortable: true
      })
      this.showDoctorSelection = true
    }

    await this._getPatients()
  }
  public async cleanFilter() {
    this.searchQuery = ''
    await this._getPatients(1, this.searchQuery)
  }
  public async filter() {
    await this._getPatients(1, this.searchQuery)
  }
  public async toggleForm() {
    this.formExpanded = !this.formExpanded
    if (!this.formExpanded) {
      this.form = {} as PatientVM
      this.isEditing = false
    } else {
      await this.fillFields()
    }
  }
  public toggleRow() {
    this.items = this.items.map(c => {
      return { ...c, _showDetails: !c._showDetails }
    })
    this.expandAllRows = !this.expandAllRows
  }
  private async _getPatients(pageNumber = 1, searchQuery = '') {
    const response = await getPatientsList(
      pageNumber,
      this.perPage,
      searchQuery
    )
    this.items = response.data
    this.pagination = JSON.parse(response.headers['x-pagination'])
  }
  public formatDate(value: string) {
    if (value) return moment(value).format('MM/DD/YYYY')
  }
  public async fillFields() {
    // if (!this.formExpanded) return
    const response = await getResolve()
    if (!response) return
    this.insurances = response.insurances.sort((a: SelectVM, b: SelectVM) =>
      a.label.localeCompare(b.label)
    )
    this.genders = response.genders
    this.insurancesPlans = response.insurancePlans
    const doc = await Doc()
    this.doctorList = doc.doctors
    // console.log(response)
  }
  public async edit(item: PatientVM) {
    await this.fillFields()
    this.isEditing = true
    this.form = { ...item, dob: moment(item.dob).toDate() }
    this.selectedDoctor = this.doctorList.filter(
      c => c.value == item.doctorId.toString()
    )[0]
    this.formExpanded = true
    this.formatted = this.formatDate(this.form.dob.toString())
  }

  public async create(): Promise<void> {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }

    const obj = {
      ...this.form,
      insuranceName: this.insurances.find(
        c => c.value == this.form.insuranceID.toString()
      )?.label,
      insurancePlan: this.insurancesPlans.find(
        c => c.value == this.form.planID.toString()
      )?.label,
      sex: this.genders.find(c => c.value == this.form.sexId.toString())?.label,
      doctorID: this.selectedDoctor ? +this.selectedDoctor.value : null,
      email2: null
    } as PatientVM

    if (!this.isEditing) {
      await postPatient(obj)
    } else {
      await updatePatient(obj)
    }
    this.isEditing = false
    this.formExpanded = false
    this.form = {} as PatientVM
    this._getPatients()
  }
  public changeStatus(checked: boolean, item: Patient) {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `This patient status will be set as ${
          checked ? 'Active' : 'Inactive'
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          const obj: UpdatePatientStatus = {
            doctorId: item.doctorId,
            documentID: item.documentID,
            statusValue: checked
          }

          updatePatientStatus(obj).then(c => {
            this._getPatients()
          })
          this.$swal.fire(
            `${checked ? 'Activated' : 'Deactivated'}!`,
            `The patient has been ${
              checked ? 'Activated' : 'Deactivated'
            } successfully`,
            'success'
          )
        } else {
          item.patientStatus = !item.patientStatus
        }
      })
  }
}
