
import PNInformation from '@/components/smartPN/PNInformation.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Diagnostic from '@/components/smartPN/Diagnostic.vue'
import Summary from '@/components/smartPN/Summary.vue'
import DX from '@/components/smartPN/DX.vue'
import PdfMake from 'pdfmake'
import html2pdf from 'html2pdf.js'
import { PostSmartPN, postTempDX } from '@/components/admin/forms.service'
import {
  MedicalRecord,
  PostTempDX,
  RecordDiagnostic,
  VisitOptions
} from '@/models'

// import { BvEvent } from 'bootstrap-vue'
enum Tabs {
  PNINFORMATION = 'pnInfo',
  DIAGNOSTIC = 'diagnostic',
  DX = 'DX',
  SUMMARY = 'summary'
}
import VueHtml2pdf from 'vue-html2pdf'

@Component({
  components: {
    PNInformation,
    Diagnostic,
    Summary,
    DX,
    VueHtml2pdf
  }
})
export default class SmartPN extends Vue {
  @Prop() public recordID!: string
  public isGeneratingPDF = false
  public isDone = false
  selectedItems: Record<string, string> = {}
  otherValues: Record<string, string> = {}
  form = {
    RecordID: this.recordID,
    VisitOptionID: '',
    jsonDXTable: ''
  } as MedicalRecord
  diagnostics = ''
  public enabledTabs = [true, false, false, false, false]
  serviceDate = null
  selectedCPT = null
  pnInfo: Record<string, string> = {}
  postTempDX = {} as PostTempDX
  timeOptions: VisitOptions[] = []
  dataAvailable = false
  billed: RecordDiagnostic[] = []
  vitalSigns: Record<string, string> = {}
  pdfComponent: VueHtml2pdf | null = null
  async handleTabs(a, b, c) {
    c.preventDefault()
    this.nextTab()
  }
  generatePdfAsync() {
    return new Promise((resolve, reject) => {
      const pdfRef = this.$refs.html2Pdf as any

      // Generate the PDF
      pdfRef.generatePdf({
        hasDownloaded: (pdfBlob: Blob) => {
          // console.log(pdfBlob)
          // console.log(111111)
          // Resolve the Promise when PDF is generated successfully
          resolve(pdfBlob)
        },
        errorCallback: (error: any) => {
          // Reject the Promise if there's an error
          reject(error)
        }
      })
    })
  }
  @Watch('base64data', { deep: true, immediate: true })
  saveData(newVal) {
    if (newVal != null) {
      const obj = this.extractMedicalRecord(this.form)
      obj.pnblob = this.base64data
      // this.isGeneratingPDF = true
      PostSmartPN(obj)
      this.enabledTabs = [false, false, false, true]
      this.isDone = true
    }
  }
  public submit() {
    this.scrollToTop()
    this.isGeneratingPDF = true

    this.$refs.html2Pdf.generatePdf()
    // try {
    //   // Call the generatePdf and await its completion
    //   await this.generatePdfAsync()
    //   this.scrollToTop()
    //   console.log('PDF generation complete!')
    // } catch (error) {
    //   console.error('Error generating PDF:', error)
    // }

    // const obj = this.extractMedicalRecord(this.form)
    // obj.pnblob = this.base64data
    // // console.log(obj)
    // this.isGeneratingPDF = true
    // await PostSmartPN(obj)
    // this.$nextTick(() => {
    //   // console.log('Generating PDF with content:', this.summaryHtml)
    //   setTimeout(() => {
    //     // this.$refs.pdf.generatePdf();
    //     this.$refs.html2Pdf.generatePdf()
    //   }, 500)
    // })
    // PostSmartPN(obj).then(c => {
    //   this.isGeneratingPDF = true
    // })
    // if (this.currentTab < this.enabledTabs.length - 1) {
    //   this.$set(this.enabledTabs, this.currentTab + 1, true)
    // }
    // console.log(this.$refs)
  }

  extractMedicalRecord(obj: any): MedicalRecord {
    // const {
    //   RecordID,
    //   DoctorID,
    //   VisitOptionID,
    //   OtherCC,
    //   OtherONSET,
    //   OtherFREQUENCY,
    //   OtherPRECIPITATING_FACTOR,
    //   OtherPREVIOUS_SIMILAR_SYMPTOMS,
    //   OtherALLEVIATING,
    //   OtherAGGRAVATING,
    //   OtherASSOCIATED_SYMPTOMS,
    //   OtherPMHX,
    //   OtherREASON_OF_PATIENT_LAST_VISIT,
    //   OtherINFORMATION_OF_ALL_PRIOR_ILLNESSES,
    //   OtherALLERGIES,
    //   OtherMEDICATION_AT_HOME,
    //   OtherSURGICAL_HISTORY,
    //   OtherIMMUNIZATION,
    //   OtherFAMILY_HISTORY_FATHER,
    //   OtherFAMILY_HISTORY_MODHER,
    //   OtherFAMILY_HISTORY_SIBLINGS,
    //   OtherCONSUMPTION,
    //   OtherHEALTH_HABITS,
    //   OtherSEXUAL_HISTORY,
    //   OtherOCCUPATION,
    //   OtherFAMILY_COMPOSITION,
    //   OtherMEDICATION_TO_TREAT_PATIENT_CC,
    //   OtherMARTIAL_STATUS,
    //   OtherCONSTITUTIONAL_SYMPTOMS,
    //   OtherCARDIOVASCULAR_SYMPTOMS,
    //   OtherRESPIRATORY,
    //   OtherENDOCRINE_SYMPTOMS,
    //   OtherMUSCULOSKELETAL,
    //   OtherGASTROINTESTINAL,
    //   OtherNEUROLOGICAL,
    //   OtherGENITOURINARY,
    //   OtherPSYCHIATRIC,
    //   OtherHEMATO_ONCOLOGY,
    //   OtherGENERAL_APPEARANCE,
    //   OtherSKIN,
    //   OtherHEAD,
    //   OtherEYES,
    //   OtherEARS,
    //   OtherNOSE,
    //   OtherTHROAT,
    //   OtherMOUTH,
    //   OtherNECK,
    //   OtherCHEST,
    //   OtherBREAST,
    //   OtherHEART,
    //   OtherRESPIRATORY_LUNGS,
    //   OtherGASTRO_ABDOMEN,
    //   OtherGENITALIA_MALE,
    //   OtherGENITALIA_FEMALE,
    //   OtherANUS_AND_RECTUM,
    //   OtherEXTREMITIES,
    //   OtherNEUROLOGIC,
    //   OtherLYMPHATIC_AND_MUSCULOSKELETAL,
    //   OtherOB_GYN_ASSESSMENT_HISTORY,
    //   OtherPAIN_QUALITY,
    //   OtherRADIATION,
    //   OtherCPT,
    //   jsonDXTable,
    //   UserId,
    //   Specialty,
    //   Provider,
    //   AGEOFMENARCHE,
    //   GRAVIDA,
    //   PARA,
    //   ABORTION,
    //   CSECTION,
    //   IntensityPAINSCALE,
    //   LOCATION,
    //   OTHERASSESSMENTNOTE,
    //   LMP,
    //   SERVICEDATE,
    //   DURATION
    // } = obj

    // return {
    //   RecordID,
    //   DoctorID,
    //   VisitOptionID,
    //   OtherCC,
    //   OtherONSET,
    //   OtherFREQUENCY,
    //   OtherPRECIPITATING_FACTOR,
    //   OtherPREVIOUS_SIMILAR_SYMPTOMS,
    //   OtherALLEVIATING,
    //   OtherAGGRAVATING,
    //   OtherASSOCIATED_SYMPTOMS,
    //   OtherPMHX,
    //   OtherREASON_OF_PATIENT_LAST_VISIT,
    //   OtherINFORMATION_OF_ALL_PRIOR_ILLNESSES,
    //   OtherALLERGIES,
    //   OtherMEDICATION_AT_HOME,
    //   OtherSURGICAL_HISTORY,
    //   OtherIMMUNIZATION,
    //   OtherFAMILY_HISTORY_FATHER,
    //   OtherFAMILY_HISTORY_MODHER,
    //   OtherFAMILY_HISTORY_SIBLINGS,
    //   OtherCONSUMPTION,
    //   OtherHEALTH_HABITS,
    //   OtherSEXUAL_HISTORY,
    //   OtherOCCUPATION,
    //   OtherFAMILY_COMPOSITION,
    //   OtherMEDICATION_TO_TREAT_PATIENT_CC,
    //   OtherMARTIAL_STATUS,
    //   OtherCONSTITUTIONAL_SYMPTOMS,
    //   OtherCARDIOVASCULAR_SYMPTOMS,
    //   OtherRESPIRATORY,
    //   OtherENDOCRINE_SYMPTOMS,
    //   OtherMUSCULOSKELETAL,
    //   OtherGASTROINTESTINAL,
    //   OtherNEUROLOGICAL,
    //   OtherGENITOURINARY,
    //   OtherPSYCHIATRIC,
    //   OtherHEMATO_ONCOLOGY,
    //   OtherGENERAL_APPEARANCE,
    //   OtherSKIN,
    //   OtherHEAD,
    //   OtherEYES,
    //   OtherEARS,
    //   OtherNOSE,
    //   OtherTHROAT,
    //   OtherMOUTH,
    //   OtherNECK,
    //   OtherCHEST,
    //   OtherBREAST,
    //   OtherHEART,
    //   OtherRESPIRATORY_LUNGS,
    //   OtherGASTRO_ABDOMEN,
    //   OtherGENITALIA_MALE,
    //   OtherGENITALIA_FEMALE,
    //   OtherANUS_AND_RECTUM,
    //   OtherEXTREMITIES,
    //   OtherNEUROLOGIC,
    //   OtherLYMPHATIC_AND_MUSCULOSKELETAL,
    //   OtherOB_GYN_ASSESSMENT_HISTORY,
    //   OtherPAIN_QUALITY,
    //   OtherRADIATION,
    //   OtherCPT,
    //   jsonDXTable,
    //   UserId,
    //   Specialty,
    //   Provider,
    //   AGEOFMENARCHE,
    //   GRAVIDA,
    //   PARA,
    //   ABORTION,
    //   CSECTION,
    //   IntensityPAINSCALE,
    //   LOCATION,
    //   OTHERASSESSMENTNOTE,
    //   LMP,
    //   SERVICEDATE,
    //   DURATION
    // }
    const {
      RecordID,
      DoctorID,
      VisitOptionID,
      OtherCC,
      OtherONSET,
      OtherFREQUENCY,
      OtherPRECIPITATING_FACTOR,
      OtherPREVIOUS_SIMILAR_SYMPTOMS,
      OtherALLEVIATING,
      OtherAGGRAVATING,
      OtherASSOCIATED_SYMPTOMS,
      OtherPMHX,
      OtherREASON_OF_PATIENT_LAST_VISIT,
      OtherINFORMATION_OF_ALL_PRIOR_ILLNESSES,
      OtherALLERGIES,
      OtherMEDICATION_AT_HOME,
      OtherSURGICAL_HISTORY,
      OtherIMMUNIZATION,
      OtherFAMILY_HISTORY_FATHER,
      OtherFAMILY_HISTORY_MODHER,
      OtherFAMILY_HISTORY_SIBLINGS,
      OtherCONSUMPTION,
      OtherHEALTH_HABITS,
      OtherSEXUAL_HISTORY,
      OtherOCCUPATION,
      OtherFAMILY_COMPOSITION,
      OtherMEDICATION_TO_TREAT_PATIENT_CC,
      OtherMARTIAL_STATUS,
      OtherCONSTITUTIONAL_SYMPTOMS,
      OtherCARDIOVASCULAR_SYMPTOMS,
      OtherRESPIRATORY,
      OtherENDOCRINE_SYMPTOMS,
      OtherMUSCULOSKELETAL,
      OtherGASTROINTESTINAL,
      OtherNEUROLOGICAL,
      OtherGENITOURINARY,
      OtherPSYCHIATRIC,
      OtherHEMATO_ONCOLOGY,
      OtherGENERAL_APPEARANCE,
      OtherSKIN,
      OtherHEAD,
      OtherEYES,
      OtherEARS,
      OtherNOSE,
      OtherTHROAT,
      OtherMOUTH,
      OtherNECK,
      OtherCHEST,
      OtherBREAST,
      OtherHEART,
      OtherRESPIRATORY_LUNGS,
      OtherGASTRO_ABDOMEN,
      OtherGENITALIA_MALE,
      OtherGENITALIA_FEMALE,
      OtherANUS_AND_RECTUM,
      OtherEXTREMITIES,
      OtherNEUROLOGIC,
      OtherLYMPHATIC_AND_MUSCULOSKELETAL,
      OtherOB_GYN_ASSESSMENT_HISTORY,
      OtherPAIN_QUALITY,
      OtherRADIATION,
      OtherCPT,
      jsonDXTable,
      UserId,
      Specialty,
      Provider,
      AGEOFMENARCHE,
      GRAVIDA,
      PARA,
      ABORTION,
      CSECTION,
      IntensityPAINSCALE,
      LOCATION,
      OTHERASSESSMENTNOTE,
      LMP,
      SERVICEDATE,
      DURATION,
      jsonDRUGSTable,
      OtherPlannote,
      OtherEncounter,
      pnblob
    } = obj

    return {
      RecordID,
      DoctorID,
      VisitOptionID,
      OtherCC,
      OtherONSET,
      OtherFREQUENCY,
      OtherPRECIPITATING_FACTOR,
      OtherPREVIOUS_SIMILAR_SYMPTOMS,
      OtherALLEVIATING,
      OtherAGGRAVATING,
      OtherASSOCIATED_SYMPTOMS,
      OtherPMHX,
      OtherREASON_OF_PATIENT_LAST_VISIT,
      OtherINFORMATION_OF_ALL_PRIOR_ILLNESSES,
      OtherALLERGIES,
      OtherMEDICATION_AT_HOME,
      OtherSURGICAL_HISTORY,
      OtherIMMUNIZATION,
      OtherFAMILY_HISTORY_FATHER,
      OtherFAMILY_HISTORY_MODHER,
      OtherFAMILY_HISTORY_SIBLINGS,
      OtherCONSUMPTION,
      OtherHEALTH_HABITS,
      OtherSEXUAL_HISTORY,
      OtherOCCUPATION,
      OtherFAMILY_COMPOSITION,
      OtherMEDICATION_TO_TREAT_PATIENT_CC,
      OtherMARTIAL_STATUS,
      OtherCONSTITUTIONAL_SYMPTOMS,
      OtherCARDIOVASCULAR_SYMPTOMS,
      OtherRESPIRATORY,
      OtherENDOCRINE_SYMPTOMS,
      OtherMUSCULOSKELETAL,
      OtherGASTROINTESTINAL,
      OtherNEUROLOGICAL,
      OtherGENITOURINARY,
      OtherPSYCHIATRIC,
      OtherHEMATO_ONCOLOGY,
      OtherGENERAL_APPEARANCE,
      OtherSKIN,
      OtherHEAD,
      OtherEYES,
      OtherEARS,
      OtherNOSE,
      OtherTHROAT,
      OtherMOUTH,
      OtherNECK,
      OtherCHEST,
      OtherBREAST,
      OtherHEART,
      OtherRESPIRATORY_LUNGS,
      OtherGASTRO_ABDOMEN,
      OtherGENITALIA_MALE,
      OtherGENITALIA_FEMALE,
      OtherANUS_AND_RECTUM,
      OtherEXTREMITIES,
      OtherNEUROLOGIC,
      OtherLYMPHATIC_AND_MUSCULOSKELETAL,
      OtherOB_GYN_ASSESSMENT_HISTORY,
      OtherPAIN_QUALITY,
      OtherRADIATION,
      OtherCPT,
      jsonDXTable,
      UserId,
      Specialty,
      Provider,
      AGEOFMENARCHE,
      GRAVIDA,
      PARA,
      ABORTION,
      CSECTION,
      IntensityPAINSCALE,
      LOCATION,
      OTHERASSESSMENTNOTE,
      LMP,
      SERVICEDATE,
      DURATION,
      jsonDRUGSTable,
      OtherPlannote,
      OtherEncounter,
      pnblob
    }
  }
  public summary = null
  public password = null
  public data = null
  public summaryHtml = null
  public base64data = null

  public generatePDF2() {
    this.isGeneratingPDF = true

    this.$nextTick(() => {
      // console.log('Generating PDF with content:', this.summaryHtml)
      setTimeout(() => {
        // this.$refs.pdf.generatePdf();
        this.$refs.html2Pdf.generatePdf()
      }, 500)
    })
  }
  public onPdfGenerated() {
    // this.$nextTick(() => {
    //   this.isGeneratingPDF = false
    //   // Perform operations that require the DOM to be updated
    // })
  }

  public generatePDF() {
    // this.$refs.pdfGenerator.generatePdf()
    const dd = {
      // userPassword: `MAG${this.password}`,
      content: [],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        subheader: {
          fontSize: 15,
          bold: true
        },
        quote: {
          italics: true
        },
        small: {
          fontSize: 8
        }
      }
    }
    // this.selectedItems
    dd.content.push(
      {
        text: 'PROVIDER INFORMATION',
        style: 'subheader'
      },
      `${this.pnInfo.doctorInfo?.firstName} ${this.pnInfo.doctorInfo?.lastName}, MD`,
      `ADDRESS: ${this.pnInfo.doctorInfo?.street} ${this.pnInfo.doctorInfo?.city} ${this.pnInfo.doctorInfo?.state} ${this.pnInfo.doctorInfo?.zip}`,
      `NPI: ${this.pnInfo.doctorInfo?.documentID} `,
      `LIC: ${this.pnInfo.doctorInfo?.mdlicensenumber}`,
      `TEL: ${this.pnInfo.doctorInfo?.phone}\n\n`,
      {
        text: 'PATIENT INFORMATION',
        style: 'subheader'
      },
      `Name: ${this.pnInfo.patientInfo?.patient}`,
      `ADDRESS: ${this.pnInfo.patientInfo.street} ${this.pnInfo.patientInfo.city} ${this.pnInfo.patientInfo.state} ${this.pnInfo.patientInfo.zip}`,
      `Date of Birth: ${this.pnInfo.patientInfo.dob} `,
      `Phone: ${this.pnInfo.patientInfo.phone}`,
      `Gender: ${this.pnInfo.patientInfo.sex}`,
      `Record: ${this.recordID}\n\n`,
      `--------------------------------------------------------------------------------------------------------------`,
      `Service Date: ${this.serviceDate}\n\n`,
      {
        text: 'SUBJECTIVE',
        style: 'subheader'
      },
      `CC: ${this.processedName(this.selectedItems['CHIEF COMPLAINT (CC)'])}\n`,
      {
        text: 'HISTORY OF PRESENT ILLNESS',
        style: 'subheader'
      },
      this.selectedItems['ONSET']
        ? `ONSET: ${this.processedName(this.selectedItems['ONSET'])}\n`
        : '',
      this.selectedItems['LOCATION']
        ? `LOCATION: ${this.processedName(this.selectedItems['LOCATION'])}\n`
        : '',
      this.selectedItems['DURATION']
        ? `DURATION: ${this.processedName(this.selectedItems['DURATION'])}\n`
        : '',
      this.selectedItems['INTENSITY']
        ? `INTENSITY: ${this.processedName(this.selectedItems['INTENSITY'])}\n`
        : '',
      this.selectedItems['QUALITY']
        ? `QUALITY: ${this.processedName(this.selectedItems['QUALITY'])}\n`
        : '',
      this.selectedItems['FREQUENCY']
        ? `FREQUENCY: ${this.processedName(this.selectedItems['FREQUENCY'])}\n`
        : '',
      this.selectedItems['RADIATION']
        ? `RADIATION: ${this.processedName(this.selectedItems['RADIATION'])}\n`
        : '',
      this.selectedItems['ALLEVIATING']
        ? `ALLEVIATING: ${this.processedName(
            this.selectedItems['ALLEVIATING']
          )}\n`
        : '',
      this.selectedItems['AGGRAVATING']
        ? `AGGRAVATING: ${this.processedName(
            this.selectedItems['AGGRAVATING']
          )}\n`
        : '',
      this.selectedItems['PRECIPITATING FACTOR']
        ? `PRECIPITATING FACTOR: ${this.processedName(
            this.selectedItems['PRECIPITATING FACTOR']
          )}\n`
        : '',
      this.selectedItems['PREVIOUS SIMILAR SYMPTOMS']
        ? `PREVIOUS SIMILAR (IF ANY): ${this.processedName(
            this.selectedItems['PREVIOUS SIMILAR SYMPTOMS']
          )}\n`
        : '',
      this.selectedItems['MEDICATION']
        ? `THE MEDICATION (IF ANY) TO TREAT THE PATIENT'S CC: ${this.processedName(
            this.selectedItems['MEDICATION']
          )}\n`
        : '',
      this.selectedItems['ASSOCIATED SYMPTOMS']
        ? `ASSOCIATED SYMPTOMS: ${this.processedName(
            this.selectedItems['ASSOCIATED SYMPTOMS']
          )}\n`
        : '',
      // )
      // {
      //   text: `Service Date:`,
      //   style: 'subheader'
      // },
      // `${this.serviceDate} \n\n`,
      // {
      //   text: `CPT CODE:`,
      //   style: 'subheader'
      // },
      {
        text: `Assessment \n\n`,
        style: 'header'
      },
      {
        text: `DX: \n\n`,
        style: 'subheader'
      },
      {
        style: 'tableExample',
        ref: 'dxTable',
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            ['Code', 'Description', 'Clinical Manifestations', 'Treatment']
          ]
        }
      }
    )
    const dxTable = dd.content.filter(r => r.ref == 'dxTable')[0]
    const jsonDx = JSON.parse(this.form.jsonDXTable).table
    // console.log(jsonDx)
    jsonDx.forEach(item => {
      dxTable.table.body.push([
        item.DiagnosticID,
        item.Descriptions,
        item.Clinical_Manifestations,
        item.Treatment
      ])
    })

    dd.content.push(
      `\n\n A ${this.pnInfo.patientInfo.age} years old
          ${this.getGender(
            this.pnInfo.patientInfo.sex
          )} with Past Medical History (${this.processedName(
        this.selectedItems['PMHX']
      )}), presents to office with the following CC: ${this.processedName(
        this.selectedItems['CC']
      )}. Patient states started: Onset: ${this.processedName(
        this.selectedItems['ONSET']
      )}, Precipitating event: ${this.processedName(
        this.selectedItems['PRECIPITATING FACTOR']
      )}, Alleviating: ${this.processedName(
        this.selectedItems['ALLEVIATING']
      )} Aggravating: ${this.processedName(
        this.selectedItems['AGGRAVATING']
      )} Associated Symptoms: ${this.processedName(
        this.selectedItems['ASSOCIATED SYMPTOMS']
      )}. On exam the
          patient appears ill an has the following:\n\n`
    )
    for (const key in this.summary) {
      if (Object.prototype.hasOwnProperty.call(this.summary, key)) {
        dd.content.push(
          {
            text: `${key}`,
            style: 'subheader'
          },
          `${this.processData(this.summary[key], key)}\n\n`
        )
        // console.log(this.processData(this.summary[key], key))
      }
    }
    // this.summary.forEach((a, b) => {
    //   console.log(this.processData(a, b))
    //   console.log(a, b)
    // })
    dd.content.push()
    // <p v-for="(selected, category) in localSelected" :key="category">
    //       <strong>{{ category }}:</strong> {{ processData(selected, category) }}
    //     </p>
    // PdfMake.docDefinition = {
    //   userPassword: '123'
    // }
    // console.log(this.selectedItems)
    PdfMake.createPdf(dd).open()
  }
  getPartAfterUnderscore(str) {
    return str.split('_')[1]
  }
  public created() {
    this.password = this.generateRandomPassword(4)
    // this.visualizePDF()
  }
  generateRandomPassword(length = 12) {
    const charset =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-='
    let password = ''

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      password += charset[randomIndex]
    }

    return password
  }

  processData(name: string, category) {
    // console.log(name, category)
    if (typeof name == 'object') {
      const results = name
        .map(this.getPartAfterUnderscore)
        .map(i =>
          i === 'OTHER' || i === 'COMMENT' || i === 'comment'
            ? this.otherValues[category]
            : i
        )

      return results.join(', ')
    }

    if (
      this.processedName(name)?.toLowerCase() === 'other' ||
      this.processedName(name)?.toLowerCase() === 'comment'
    ) {
      this.summary[category] = this.otherValues[category]
      return this.summary[category]
    } else {
      return this.processedName(name)
    }
  }

  async visualizePDF() {
    console.log(1)
  }
  base64ToBlob(base64: string): Blob {
    const byteCharacters = atob(base64.split(',')[1])
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: 'application/pdf' })
  }
  async hasDownloaded(pdfBlob: Blob) {
    this.isGeneratingPDF = true

    this.base64data = await this.blobToBase64(pdfBlob)
    // console.log(this.base64data)
    // console.log(base64data)
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }
  handlePdfRef(val) {
    this.pdfComponent = val
  }
  handleSummaryHtml(html: string) {
    this.summaryHtml = html
  }

  handleVitalSigns(val) {
    this.vitalSigns = val
    // console.log(this.data.filter(c => val[c.visitOptionDescription]))
    // console.log(val)
  }
  handleCC(val) {
    // console.log(val)
    for (const header in val) {
      if (Object.prototype.hasOwnProperty.call(val, header)) {
        const element = val[header]
        // console.log(header)
        // console.log(element)
        for (const group in element) {
          if (Object.prototype.hasOwnProperty.call(element, group)) {
            const g = element[group]
            // console.log(
            //   g.filter(
            //     c =>
            //       (c.visitOptionDescription = this.processData(
            //         this.selectedItems[group],
            //         group
            //       ))
            //   )[0]
            // )
          }
        }
        // {{ processData(localSelected[group], group) }}

        // element.forEach(el => {
        //   console.log(el)
        // })
        // for (const k in element) {
        //   if (Object.prototype.hasOwnProperty.call(element, k)) {
        //     const el = element[key]
        //     console.log(el)
        //   }
        // }
      }
    }

    // console.log(this.selectedItems.filter(c=> ))
  }

  handleSummary(values) {
    this.summary = values
  }
  handleMedication(value) {
    // console.log(value)
    // let result = null
    if (value != null) {
      const obj = {
        table: value
      }
      // console.log(value)
      this.form.jsonDRUGSTable = JSON.stringify(obj)
      // console.log(this.form.jsonDRUGSTable)
    } else {
      this.form.jsonDRUGSTable = null
    }
  }
  handleTime(value: VisitOptions[]) {
    this.timeOptions = value
  }
  processedName(name: string) {
    if (name) {
      const parts = name.split('_')
      return parts.length > 1 ? parts.slice(1).join('_') : name
    }
  }
  getGender(value: string) {
    return value == 'M' ? 'Male' : 'Female'
  }
  handleBilled(newSelected: RecordDiagnostic[]) {
    this.billed = newSelected
    const billedDX = this.billed.map(c => {
      return {
        DiagnosticID: c.diagnosticId,
        Descriptions: c.descriptions,
        Clinical_Manifestations: c.clinical_Manifestations,
        Treatment: c.treatment
      }
    })
    const obj = {
      table: billedDX
    }
    this.form.jsonDXTable = JSON.stringify(obj)
  }
  handleUpdatePnInfo(newSelected: Record<string, string>) {
    this.pnInfo = newSelected
    this.dataAvailable = true
    this.form.Specialty = newSelected.doctorInfo.speciality
  }
  handleUpdateSelected(newSelected: Record<string, string>) {
    this.selectedItems = newSelected

    this.form.VisitOptionID = this.processObjectToString(newSelected)
    this.form = {
      ...this.form,
      ...this.changeKeys(newSelected, this.replaceSpacesWithUnderscore)
    }
    this.form['AGEOFMENARCHE'] = this.form['AGE_OF_MENARCHE']
    this.form['IntensityPAINSCALE'] = this.form['INTENSITY_PAIN_SCALE']
    this.form['OTHERASSESSMENTNOTE'] = this.form['OTHER_ASSESSMENT_NOTE_']
    this.form['CSECTION'] = this.form['C-SECTION']
  }
  handleTreatment(newSelected: RecordDiagnostic) {
    const billedDX = this.billed
      .filter(c => c.diagnosticId == newSelected.diagnosticId)
      .map(c => {
        return {
          DiagnosticID: c.diagnosticId,
          Descriptions: c.descriptions,
          Clinical_Manifestations: c.clinical_Manifestations,
          Treatment: c.treatment
        }
      })
    const obj = {
      table: billedDX
    }
    this.form.jsonDXTable = JSON.stringify(obj)
  }
  handleClinical(newSelected: RecordDiagnostic) {
    const billedDX = this.billed
      .filter(c => c.diagnosticId == newSelected.diagnosticId)
      .map(c => {
        return {
          DiagnosticID: c.diagnosticId,
          Descriptions: c.descriptions,
          Clinical_Manifestations: c.clinical_Manifestations,
          Treatment: c.treatment
        }
      })
    const obj = {
      table: billedDX
    }
    this.form.jsonDXTable = JSON.stringify(obj)
  }
  handleUpdateOtherValues(newOtherValues: Record<string, string>) {
    this.otherValues = newOtherValues
    const obj = this.changeKeys(this.otherValues, this.prefixKey)

    this.form = { ...this.form, ...obj }
  }
  replaceSpacesWithUnderscore(key) {
    return key.replace(/[\s/]+/g, '_').toUpperCase()
  }
  prefixKey(key) {
    return `Other${this.replaceSpacesWithUnderscore(key)}`
  }
  changeKeys(obj, transformKey) {
    const newObj = {}
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const newKey = transformKey(key)
        newObj[newKey] = obj[key]
      }
    }
    return newObj
  }

  handleUpdateCpt(newVal: any) {
    this.selectedCPT = newVal
    if (newVal == 660) {
      this.form.VisitOptionID = '660'
    } else if (typeof newVal == 'string') {
      this.form.OtherCPT = newVal
    } else {
      this.form.VisitOptionID = newVal.visitOptionID.toString()
      this.form['CPT'] = newVal.visitOptionID.toString()

      this.form.OtherCPT = null
    }
    // console.log(newVal)
  }
  handleUpdateServiceDate(newVal: string) {
    this.serviceDate = newVal
    this.form.SERVICEDATE = new Date(newVal)
  }
  handleDiagnostics(newVal) {
    this.diagnostics = newVal.map(c => c.diagnosticId).join()
  }
  handleData(val) {
    // console.log(val.filter(c => this.selectedItems[c.visitOptionDescription]))

    this.data = val
  }

  public selected = {}
  public tab = Tabs
  public currentTab = 0
  private tabArray = [Tabs.PNINFORMATION, Tabs.DIAGNOSTIC, Tabs.SUMMARY]
  get tabIndexes() {
    return this.tabArray.map((_, index) => index)
  }
  get selectedData() {
    return this.selectedItems
  }
  @Watch('enabledTabs', { deep: true, immediate: true })
  onEnabledTabsChange(newVal: boolean[]) {
    // console.log(11)
    if (newVal[this.currentTab + 1]) {
      this.$nextTick(() => {
        this.currentTab += 1
      })
    }
  }
  public async nextTab() {
    const stepIsValid = await this.validateChild()
    // console.log(stepIsValid)
    if (!stepIsValid) {
      this.scrollToFirstError()
      return
    }
    this.scrollToTop()
    if (this.currentTab == 1) {
      const obj = {
        visitOptionID: this.processObjectToString(this.selectedItems),
        recordID: this.recordID,
        diagnostics: this.diagnostics,
        serviceDate: this.serviceDate,
        specialty: this.pnInfo?.doctorInfo?.speciality
      } as PostTempDX

      await postTempDX(obj)
    }
    if (this.currentTab < this.enabledTabs.length - 1) {
      // this.enabledTabs[this.currentTab + 1] = true
      this.$set(this.enabledTabs, this.currentTab + 1, true)
      // this.currentTab++
      // Explicitly set currentTab after enabling the next tab
      // this.currentTab = this.currentTab + 1
    }

    // if (this.currentTab < this.enabledTabs.length - 1) {
    //   this.enabledTabs[this.currentTab + 1] = true
    //   this.$nextTick(() => {
    //     this.currentTab++
    //   })
    // }
  }

  public scrollToFirstError() {
    const firstErrorElement = this.$el.querySelector('.is-invalid')
    // console.log(firstErrorElement)
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
  public scrollToTop() {
    const firstErrorElement = this.$el.querySelector('.card-header')
    // console.log(firstErrorElement)
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth' })
    }
  }
  public prevTab() {
    this.currentTab--
  }
  get isSelectedItemsNotEmpty() {
    return Object.keys(this.selectedItems).length > 0
  }
  public async validateChild() {
    const childObserver = this.$refs[this.tabArray[this.currentTab]] as any
    let validation: boolean
    // if(childObserver)
    if (childObserver && childObserver.$refs.observer) {
      const response = await childObserver.$refs.observer.validate()
      validation = response
    } else {
      // this.currentTab++
      validation = true
    }
    return validation
  }
  public processObjectToString(obj) {
    const values = []
    // console.log(obj)
    Object.values(obj).forEach(value => {
      if (typeof value === 'string') {
        // console.log(value, typeof value)
        // If the value is a string, extract part before the first underscore or whitespace
        if (value.includes('_')) {
          values.push(value.split(/[_\s]/)[0])
        }
      } else if (Array.isArray(value)) {
        // console.log(value)
        // If the value is an array of strings, extract part before the first underscore or whitespace for each element
        value.forEach(item =>
          typeof item == 'string' ? values.push(item.split(/[_\s]/)[0]) : ''
        )
      }
    })

    // Join all values into a single comma-separated string
    return Array.from(new Set(values.filter(val => !isNaN(val))))
      .sort()
      .join(', ')
  }
}
