import {
  GalleryPostVM,
  UpdateVideos,
  VideoResolve,
  Videos,
  VideosVM
} from '@/models'
import axios, { AxiosResponse } from 'axios'
const API_URL = 'Gallery'
import store from '@/store'

export const postVideo = async (
  model: GalleryPostVM
): Promise<GalleryPostVM[]> => {
  const { data } = await axios.post(API_URL + '/Upload', model)
  return data
}
export const getGallery = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
): Promise<AxiosResponse<Videos[]>> => {
  const response = await axios.get(
    API_URL +
      '/Gallery' +
      `?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  )
  return response
}
export const getVideoResolve = async (
  id: number,
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
): Promise<VideoResolve> => {
  const { data } = await axios.get(
    API_URL +
      '/GalleryResolve' +
      `?videoID=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  )
  return data
}

export const getVideoFile = async (): Promise<any> => {
  const data = await axios.get('http://localhost:8000/video?id=1', {
    headers: {
      range: 'bytes=0-'
    }
  })
  return data
}
export const deleteVideo = async (video: VideosVM): Promise<any> => {
  const { data } = await axios.put<any>(API_URL + `/DeleteVideo`, video)
  return data
}
export const updateVideo = async (video: UpdateVideos): Promise<any> => {
  const { data } = await axios.put<any>(API_URL + `/UpdateVideo`, video)
  return data
}
// export const upload = async (): Promise<any> => {
//   const { data } = await axios.put<any>(API_URL + `/UpdateVideo`, video)
//   return data
// }

export const uploadFile = async (file: FormData): Promise<any> => {
  const config = {
    onUploadProgress: (progressEvent: ProgressEvent) =>
      // console.log(progressEvent.loaded)
      store.dispatch(
        'app/addProgress',
        (progressEvent.loaded / progressEvent.total) * 100
      )
  }
  const { data } = await axios.post(API_URL + `/UploadFile`, file, config)
  return data
}
