
import { getVideoResolve } from '@/components/gallery/gallery.service'
import { VideoResolve, Videos } from '@/models'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class GalleryFile extends Vue {
  public id: string | null = null
  public videoResolve = {} as VideoResolve
  public currentVideo = {} as Videos
  public videos: Videos[] = []
  public isLoaded = false
  public videoSource = ''
  async created() {
    // fetch(process.env.BASE_URL + 'config2.txt')
    //   .then(res => res.json())
    //   .then(data => {

    //   })
    this.videoSource = `https://gallery.magdashboard.com:8044/video?id=${this.$route.params.id}#t=0.1`
    await this.getVideos()
  }

  async getVideos() {
    this.id = this.$route.params.id
    this.videoResolve = await getVideoResolve(+this.id)
    this.currentVideo = this.videoResolve.video
    this.videos = this.videoResolve.videos.filter(
      c => c.videoID != this.currentVideo.videoID
    )
    this.isLoaded = true
  }
  goToGallery() {
    this.$router.push({ name: 'Gallery' })
  }
  async goToMedia(a: string) {
    this.isLoaded = false
    this.$router.push({ name: 'GalleryFile', params: { id: a } })
    await this.getVideos()
    this.videoSource = `https://gallery.magdashboard.com:8044/video?id=${this.$route.params.id}#t=0.1`

    this.isLoaded = true
  }
  public formatDate(value: string) {
    return moment(value).format('MM/DD/YYYY')
  }
}
