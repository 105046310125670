import {
  Diagnostic,
  PostRecordDiagnostic,
  RecordDiagnosticResult,
  UpdateDiagnosticStatus,
  UpdateDiagnostic,
  PostRecordDiagnosticTemp,
  RecordDiagnosticTempResult
} from '@/models'
import axios, { AxiosResponse } from 'axios'
const API_URL = 'Diagnostics'

export const getDiagnostics = async (
  pageNumber: number,
  pageSize: number,
  searchQuery: string
): Promise<AxiosResponse<Diagnostic[]>> => {
  const response = await axios.get<Diagnostic[]>(
    API_URL +
      `?getCanceled=false&searchQuery=${searchQuery}&PageNumber=${pageNumber}&PageSize=${pageSize}`
  )
  return response
}

export const getLastRecord = async (): Promise<RecordDiagnosticTempResult[]> => {
  const response = await axios.get<RecordDiagnosticTempResult[]>(
    API_URL + '/GetLastRecord'
  )
  return response.data
}

export const postRecordDiagnostic = async (
  model: PostRecordDiagnostic
): Promise<RecordDiagnosticResult> => {
  const response = await axios.post(API_URL, model)
  return response.data
}
export const postRecordDiagnosticTemp = async (
  model: PostRecordDiagnosticTemp
): Promise<RecordDiagnosticTempResult[]> => {
  const response = await axios.post(API_URL + `/TempDiagnostics`, model)
  return response.data
}
export const createDiagnostic = async (
  model: Diagnostic
): Promise<Diagnostic> => {
  const response = await axios.post(API_URL + `/CreateDiagnostic`, model)
  return response.data
}
export const updateDiagnosticStatus = async (
  model: UpdateDiagnosticStatus
): Promise<UpdateDiagnosticStatus[]> => {
  const { data } = await axios.put<UpdateDiagnosticStatus[]>(
    API_URL + `/UpdateDiagnosticStatus`,
    model
  )
  return data
}
export const updateDiagnostic = async (
  model: UpdateDiagnostic
): Promise<UpdateDiagnostic[]> => {
  const { data } = await axios.put<UpdateDiagnostic[]>(
    API_URL + `/UpdateDiagnostic`,
    model
  )
  return data
}
export const deleteDiagnostic = async (
  recordId: string,
  diagnosticId: string,
  serviceDate: Date | null,
  queue: boolean
): Promise<string> => {
  const { data } = await axios.delete(API_URL + `/DeleteDiagnostic`, {
    params: {
      RecordId: recordId,
      DiagnosticId: diagnosticId,
      ServiceDate: serviceDate,
      Queue: queue
    }
  })
  return data
}
export const deleteAllDiagnostics = async (
  recordId: string
): Promise<string> => {
  const { data } = await axios.delete(API_URL + `/deleteAllDiagnostics`, {
    params: { RecordId: recordId }
  })
  return data
}
export default {
  getDiagnostics,
  postRecordDiagnostic,
  updateDiagnosticStatus
}
