
declare global {
  interface Window {
    gapi: any
  }
}

import {
  NotificationMessage,
  PostNotification,
  SelectVM,
  SignUpBillingPrograms,
  SignUpForm,
  SignUpRecordPrograms,
  SignUpServices,
  UserVM
} from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  getDoctorProfile,
  getSignUpResolve,
  sendNotification
} from '@/components/admin/users/users.service'
import DeviceDetector from 'device-detector-js'
import { namespace } from 'vuex-class'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import Terms from '../Terms.vue'
import PrivacyPolicy from '../PrivacyPolicy.vue'
import HIPAA from '../HIPAA.vue'
import {
  getGenders,
  getProgramOrServices,
  signUp,
  updateDoctor
} from '@/components/doctors/doctors.service'
import CompleteProfile from './CompleteProfile.vue'
const AppModule = namespace('app')
@Component({
  directives: {
    GoogleSignInButton
  },
  components: {
    Terms,
    PrivacyPolicy,
    HIPAA
  }
})
export default class SignUp extends Vue {
  @AppModule.Action('sendSignalRMessage') sendSignalRMessage!: (
    message: NotificationMessage
  ) => Promise<void>
  @AppModule.Getter
  public _isLoading!: () => boolean
  @Prop()
  complete!: boolean
  public show2FA = false
  public hint = null
  public form = {} as UserVM
  public genders: SelectVM[] = []
  public formatted = ''
  public selectedGender: string | null = null
  public path = ''
  public billingSoftware: SignUpBillingPrograms[] = []
  public signUpForm = {} as SignUpForm
  public showOtherBilling = false
  public showOtherProgram = false
  public signUpSuccess = false
  public selectedSoftware: SignUpBillingPrograms[] = []
  public electronicRecord: SignUpRecordPrograms[] = []
  public otherServices: SignUpServices[] = []
  public selectedRecord: SignUpRecordPrograms[] = []
  public selectedServices: SignUpServices[] = []
  public formType = null
  public disable = {
    terms: true,
    privacy: true,
    hipaa: true
  }
  public accepted = {
    hipaa: false,
    terms: false,
    privacy: false
  }
  public disableAccept = true
  public termsAccepted = false
  public handleScroll(
    { target: { scrollTop, clientHeight, scrollHeight } },
    type: string
  ) {
    if (scrollTop + clientHeight >= scrollHeight - 2) {
      if (type == 't') {
        this.disable.terms = false
      } else if (type == 'p') {
        this.disable.privacy = false
      } else {
        this.disable.hipaa = false
      }
    }
  }
  public onInput(ctx) {
    const a = moment(ctx).toDate()
    if (a.toString() != 'Invalid Date') this.form.dob = a
  }
  public onContext(ctx) {
    this.formatted = moment(ctx).format('MM-DD-YYYY')
  }
  public onBillingSoftwareInput(e) {
    this.signUpForm.rmtBillingProgram = e.join(',')
    const id = this.billingSoftware.filter(
      c => c.billingProgramName == 'Other'
    )[0].billingProgramID

    this.showOtherBilling = e.includes(id)
  }
  public onRecordSoftwareInput(e) {
    this.signUpForm.rmtRecordProgram = e.join(',')
    const id = this.electronicRecord.filter(
      c => c.recordProgramName == 'Other'
    )[0].recordProgramID

    this.showOtherProgram = e.includes(id)
  }
  public onOtherServicesInput(e) {
    this.signUpForm.rmtServices = e.join(',')
  }
  goBack() {
    this.show2FA = false
  }

  public async mounted() {
    this.$store.dispatch('app/initSignalR', 2)
    const result = await getSignUpResolve()
    const genders = await getGenders()
    this.genders = genders
    this.billingSoftware = result.billingPrograms
    this.electronicRecord = result.recordPrograms
    this.otherServices = result.services
    this.complete == true
      ? (this.formType = 'Complete Profile')
      : (this.formType = 'Registration Form')

    if (localStorage.getItem('currentUser') && this.complete) {
      const u = localStorage.getItem('currentUser')
      const currentUser = JSON.parse(u || '{}')
      this.signUpForm = await getDoctorProfile(currentUser.userName)
      this.selectedGender = this.genders.find(c => c.label == 'M').value
      console.log(this.signUpForm)
      this.formatted = this.formatDate(this.signUpForm.dob.toString())
      const data = await getProgramOrServices(this.signUpForm.documentID)
      console.log(data)
      const groupedData = data.reduce((acc, curr) => {
        if (!acc[curr.porS_Identifier]) {
          acc[curr.porS_Identifier] = []
        }
        acc[curr.porS_Identifier].push(curr.porS_Id)
        return acc
      }, {})

      const result = Object.keys(groupedData).map(key => ({
        text: key,
        value: groupedData[key]
      }))

      this.selectedRecord = result.filter(
        c => c.text == 'Record_Program'
      )[0]?.value
      this.selectedServices = result.filter(
        c => c.text == 'Service_Required'
      )[0]?.value

      this.selectedSoftware = result.filter(
        c => c.text == 'Billing_Program'
      )[0]?.value

      console.log(result)
      console.log(data)
      this.signUpForm.otherBillingProgram = data.filter(
        c =>
          c.porS_Identifier == 'Billing_Program' &&
          c.programorServiceName2 == 'Other'
      )[0]?.programorServiceName
      this.signUpForm.otherRecordProgram = data.filter(
        c =>
          c.porS_Identifier == 'Record_Program' &&
          c.programorServiceName2 == 'Other'
      )[0]?.programorServiceName
    }
  }
  public formatDate(value: string) {
    if (value) return moment(value).format('MM-DD-YYYY')
  }
  public async SignUp() {
    // this.$bvToast.toast('Action submitted successfully', {
    //   title: 'Success',
    //   variant: 'success',
    //   solid: true,
    //   toaster: 'b-toaster-bottom-right',
    //   autoHideDelay: 5000,
    //   appendToast: true
    // })

    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }
    this.signUpForm.sex = this.genders.find(
      c => c.value == this.selectedGender
    )?.label
    this.signUpForm.policyAcceptanceFlag = true
    this.signUpForm.isAnonymous = true
    this.signUpForm.role = 'Doctor'
    this.signUpForm.speciality = 'Doctor'
    if (!this.complete) {
      const a = await signUp(this.signUpForm)
      if (a) {
        this.signUpForm = {} as SignUpForm
        this.signUpSuccess = true
      }
      const model = {
        MessageBody: ` NPI: ${this.signUpForm.documentID} Name: ${this.signUpForm.firstName} ${this.signUpForm.lastName} Email: ${this.signUpForm.email1}`,
        MessageTitle: 'New User Registration',
        UserId_M: '9',
        IsAnonymous: true
      } as PostNotification
      await sendNotification(model)
      const notificationMessage: NotificationMessage = {
        ...model,
        UserId: model.UserId_M,
        ByRole: false
      }
      await this.sendSignalRMessage(notificationMessage)
    } // console.log(a)
    else {
      const userVm = {
        ...this.signUpForm,
        email2: null,
        roleId: 5,
        insuranceID: 999,
        sexId: null,
        mobile: null,
        userId: null,
        ipaNumber: null
      } as UserVM

      const a = await updateDoctor(userVm)
      this.$router.push('/')
    }
  }
}
