
import { Component, Vue } from 'vue-property-decorator'
import { getMenu } from '@/components/admin/users/users.service'
import MenuBar from '@/components/shared/menu.vue'
import { Menu } from '@/models'
@Component({ components: { MenuBar } })
export default class Navbar extends Vue {
  public menu: Menu[] = []

  created() {
    // const sortedMenu = getMenu()
    this.menu = getMenu().sort((a, b) => a.position - b.position)
    // console.log(sortedMenu)
  }
}
