
import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  Insurance,
  PaginationVM,
  UpdateInsurance,
  UpdateInsuranceStatus
} from '@/models/index'

import {
  getInsurances,
  postInsurance,
  updateInsuranceStatus,
  editInsurance
} from '@/components/insurances/insurance.service'
import moment from 'moment'

@Component
export default class InsurancesView extends Vue {
  public fields = [
    // {
    //   key: 'insuranceID',
    //   label: 'Id'
    // },
    'index',
    {
      key: 'insuranceName',
      label: 'Name',
      sortable: true
    },
    {
      key: 'creationDate',
      label: 'Date Added',
      formatter: 'formatDate',
      sortable: true
    },
    {
      key: 'insuranceStatus',
      label: 'Status'
    },

    {
      key: 'show_details',
      label: 'Actions'
    }
  ]
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public items: Insurance[] = []
  public form = {} as Insurance
  public isEditing = false
  private async created() {
    await this._getInsurances()
  }
  @Watch('perPage')
  perPageChange() {
    this._getInsurances()
  }
  public async onPageChange(pageNumber: number) {
    await this._getInsurances(pageNumber)
  }
  get _pagination() {
    return this.pagination
  }
  public async _getInsurances(pageNumber = 1) {
    const res = await getInsurances(pageNumber, this.perPage)
    this.items = res.data
    this.pagination = JSON.parse(res.headers['x-pagination'])
  }
  public async create() {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }
    if (!this.isEditing) {
      await postInsurance(this.form)
      // if (response.some(c => c.error)) return
    } else {
      // if (!this.form.insuranceID) return

      const obj: UpdateInsurance = {
        insuranceID: this.form.insuranceID,
        insuranceName: this.form.insuranceName
      }
      // if (!obj.insuranceName) {
      //   this.$bvToast.toast('Please specify an insurance name', {
      //     title: 'Error!',
      //     variant: 'warning',
      //     solid: true,
      //     toaster: 'b-toaster-bottom-right',
      //     autoHideDelay: 5000,
      //     appendToast: true
      //   })
      //   return
      // }
      await editInsurance(obj)
      // if (response.some(c => c.error)) return
    }
    this.$root.$emit('bv::toggle::modal', 'modal', '#btnCreate')
    this.isEditing = false
    await this._getInsurances()
  }
  public changeStatus(checked: boolean, item: Insurance) {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `This insurance status will be set as ${
          checked ? 'Active' : 'Inactive'
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          const obj: UpdateInsuranceStatus = {
            insuranceID: item.insuranceID,
            statusValue: checked
          }

          updateInsuranceStatus(obj).then(() => {
            this._getInsurances()
          })
          this.$swal.fire(
            `${checked ? 'Activated' : 'Deactivated'}!`,
            `The insurance has been ${
              checked ? 'Activated' : 'Deactivated'
            } successfully`,
            'success'
          )
        } else {
          item.insuranceStatus = !item.insuranceStatus
        }
      })
  }
  public formatDate(value: string) {
    return moment(value).format('MM/DD/YYYY')
  }
  public edit(item: Insurance) {
    this.isEditing = true
    this.form = { ...item }
  }
  public addNew() {
    this.isEditing = false
  }
  public hideModal() {
    this.isEditing = false
    this.form = {} as Insurance
  }
}
