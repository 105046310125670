import {
  Doctor,
  DoctorNotePostResult,
  DoctorPostResult,
  DoctorResolve,
  MAGNotePostResult,
  PNInformationResolve,
  ProgramOrServices,
  SelectVM,
  SignUpForm,
  UpdateEntityStatus,
  UserIdentity,
  UserVM
} from '@/models'
import axios, { AxiosResponse } from 'axios'
import PNInformation from '../smartPN/PNInformation.vue'
const API_URL = 'Doctors'

export const getDoctors = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
): Promise<AxiosResponse<Doctor[]>> => {
  const response = await axios.get<Doctor[]>(
    API_URL +
      `?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  )
  return response || null
}

export const getResolve = async (): Promise<DoctorResolve> => {
  const { data } = await axios.get<DoctorResolve>(API_URL + `/DoctorsResolve`)
  return data
}

export const getGenders = async (): Promise<SelectVM[]> => {
  const { data } = await axios.get<SelectVM[]>(`Misc/Genders`)
  return data
}

export const getProgramOrServices = async (
  documentId: string
): Promise<ProgramOrServices[]> => {
  const { data } = await axios.get<ProgramOrServices[]>(
    API_URL + `/DoctorsProgramOrServices?documentId=${documentId.toString()} `
  )
  return data
}
export const getDoctorInfo = async (
  record: string
): Promise<PNInformationResolve> => {
  const { data } = await axios.get<PNInformationResolve>(
    `Forms/GetPNInformation?recordID=${record} `
  )
  return data
}

export const getStaffDoctors = async (): Promise<UserIdentity[]> => {
  const { data } = await axios.get<UserIdentity[]>(API_URL + `/GetStaffDoctors`)
  return data
}

export const updateDoctorStatus = async (
  model: UpdateEntityStatus
): Promise<UpdateEntityStatus> => {
  const { data } = await axios.put<UpdateEntityStatus>(
    API_URL + `/UpdateDoctorStatus`,
    model
  )
  return data
}
export const updateDoctor = async (model: UserVM): Promise<UserVM> => {
  const { data } = await axios.put<UserVM>(API_URL + `/UpdateDoctor`, model)
  return data
}
export const postDoctor = async (model: UserVM): Promise<DoctorPostResult> => {
  const { data } = await axios.post(API_URL, model)
  return data
}

export const signUp = async (model: SignUpForm): Promise<DoctorPostResult> => {
  const { data } = await axios.post(API_URL, model)
  return data
}

export const postDoctorNote = async (
  recordId: string,
  doctorNotes: string | null
): Promise<DoctorNotePostResult> => {
  const { data } = await axios.post(API_URL + `/CreateDoctorNote`, {
    RecordID: recordId,
    DoctorNotes: doctorNotes
  })
  return data
}

export const postMAGNote = async (
  recordId: string,
  MAGNotes: string | null
): Promise<MAGNotePostResult> => {
  const { data } = await axios.post(API_URL + `/CreateMAGNote`, {
    RecordID: recordId,
    MAGNotes: MAGNotes
  })
  return data
}
