
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getResolve } from '@/components/doctors/doctors.service'
import {
  GalleryPostVM,
  PaginationVM,
  Role,
  SelectVM,
  UpdateVideos,
  Videos,
  VideosVM
} from '@/models'
import Multiselect from 'vue-multiselect'
import {
  updateVideo,
  deleteVideo,
  getGallery,
  postVideo,
  uploadFile
} from '@/components/gallery/gallery.service'
import moment from 'moment'
import { getUserRole } from '@/components/admin/users/users.service'
import { namespace } from 'vuex-class'
const AppModule = namespace('app')

@Component({
  components: {
    Multiselect
  }
})
export default class Gallery extends Vue {
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public roles: SelectVM[] = []
  public selectedRoles: SelectVM[] = []
  public form = {} as GalleryPostVM
  public isEditing = false
  public isExpanded = false
  public searchQuery = ''
  public sortType = 'sort-down'
  public gallery: Videos[] = []
  public showControls = false
  public selectedForm = 0
  public categories: SelectVM[] = [
    { value: '1', label: 'Coding' },
    { value: '2', label: 'Documentation' },
    { value: '3', label: 'English' },
    { value: '4', label: 'Spanish' }
  ]
  public isAdmin = false
  public selectedCategory = {} as SelectVM
  public selectedFilter = ({} as SelectVM) || null
  public selectedFilterRole: SelectVM[] = []
  public data: Videos[] = []
  @AppModule.Getter
  public _progress!: () => number
  goToMedia(a: string) {
    this.$router.push({ name: 'GalleryFile', params: { id: a } })
  }
  @Watch('perPage')
  perPageChange() {
    this.getGallery()
  }
  public async onPageChange(pageNumber: number) {
    await this.getGallery(pageNumber, this.searchQuery)
  }
  public async getGallery(pageNumber = 1, searchQuery = '') {
    const response = await getGallery(pageNumber, this.perPage, searchQuery)
    this.gallery = response.data
    this.pagination = JSON.parse(response.headers['x-pagination'])
  }
  get _pagination() {
    return this.pagination
  }

  @Watch('selectedFilter')
  onFilterChange() {
    if (this.selectedFilter) {
      this.gallery = this.data.filter(
        c => c.categoria == this.selectedFilter.label
      )
    } else {
      this.gallery = this.data
    }
  }
  @Watch('selectedFilterRole')
  onFilterRoleChange() {
    if (this.selectedFilterRole) {
      const roles = this.selectedFilterRole.map(c => c.value).join(',')

      this.gallery = this.data.filter(c => c.rolesChain.includes(roles))
    } else {
      this.gallery = this.data
    }
  }
  async created() {
    this.roles = (await getResolve()).roles
    await this.getGallery()
    this.data = [...this.gallery]
    if (getUserRole() == Role.Admin || getUserRole() == Role.Superuser) {
      this.showControls = true
    }
  }
  cleanFilter() {
    this.selectedFilter = null
  }
  close() {
    this.isExpanded = false
    this.isEditing = false
    this.form = {} as GalleryPostVM
  }
  addNew() {
    this.isExpanded = !this.isExpanded
    this.isEditing = false
  }
  async create() {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }
    const roles = this.selectedRoles.map(x => x.value).join(',')
    if (this.isEditing) {
      const a: UpdateVideos = {
        videoID: this.selectedForm,
        title: this.form.title,
        description: this.form.description,
        categoria: this.selectedCategory.label,
        previewImagePath: this.form.previewImagePath,
        rolId: roles,
        status: true,
        preview: this.form.preview
      }
      await updateVideo(a)
    } else {
      const response = await this.uploadVideo()
      if (response) {
        this.form.rolId = roles
        this.form.categoria = this.selectedCategory.label
        this.form.videoPath = response
        this.form.previewImagePath = '/'

        await postVideo(this.form)
      }
    }
    // this.gallery = { ...(await this.getGallery()) }
    this.getGallery()
    this.isExpanded = false
    this.isEditing = false
  }
  public sort() {
    this.sortType === 'sort-down'
      ? (this.sortType = 'sort-up')
      : (this.sortType = 'sort-down')

    this.gallery = this.gallery.reverse()
  }
  public async uploadVideo() {
    const formData = new FormData()
    formData.append('file', this.form.file)

    const response = await uploadFile(formData)
    return response
  }
  public editVideo(video: VideosVM) {
    this.isExpanded = true
    this.isEditing = true

    const videoRoles = video.rolesChain.split(',')
    this.selectedRoles = this.roles.filter(c => videoRoles.includes(c.value))
    this.selectedCategory = this.categories.filter(
      c => c.label == video.categoria
    )[0]
    this.form = {
      ...this.form,
      title: video.title,
      description: video.description,
      roles: this.selectedRoles
    }

    this.selectedForm = video.videoID
  }
  public deleteVideo(video: VideosVM) {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: 'This action will delete the video',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          video.status = false
          deleteVideo(video)
          this.getGallery()
          // getGallery().then(c => {
          //   this.gallery = { ...c }
          // })
        }
      })
  }
  public formatDate(value: string) {
    return moment(value).format('MM/DD/YYYY')
  }
}
