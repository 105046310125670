
import { Component, Vue, Watch } from 'vue-property-decorator'
import Navbar from '@/components/shared/navbar.vue' // @ is an alias to /src
import {
  getPatients,
  postPatient,
  updatePatientStatus,
  updatePatient,
  getResolve,
  getPatientsAll
} from '@/components/patients/patients.service'
import {
  PaginationVM,
  Patient,
  PatientInfo,
  PatientVM,
  Role,
  SelectVM,
  UpdatePatientStatus
} from '@/models'
import moment from 'moment'
import {
  getCurrentUser,
  getUserRole
} from '@/components/admin/users/users.service'
import Multiselect from 'vue-multiselect'
import { getInsuranceAgreementsResolve } from '@/components/insurances/insurance.service'
import {
  XlsxRead,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload
} from 'vue-xlsx'
import XLSX from 'xlsx'
import exportFromJSON from 'export-from-json'
import { BTable } from 'bootstrap-vue'
import xlsx from 'json-as-xlsx'
// import json2xlsx from 'json2xlsx'
@Component({
  components: {
    Navbar,
    Multiselect,
    XlsxRead,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    FileReader,
    XLSX
  }
})
export default class PatientsView extends Vue {
  public insuranceList: SelectVM[] = []

  public fields = [
    // 'index',
    {
      key: 'index',
      label: 'Index',
      sortable: true,
      order: 1
    },
    {
      key: 'npi',
      label: 'NPI',
      sortable: true,
      order: 2
    },
    {
      key: 'doctorFullName',
      label: 'Doctor Name',
      sortable: true,
      order: 3
    },
    // {
    //   key: 'documentID',
    //   label: 'Document ID',
    //   sortable: true
    // },
    {
      key: 'fullName',
      label: 'Patient Name',
      sortable: true,
      order: 4
    },

    {
      key: 'insuranceName',
      label: 'Insurance',
      sortable: true,
      order: 5
    },
    {
      key: 'insuranceContractNo',
      label: 'INS Contract No',
      sortable: true,
      order: 6
    },
    {
      key: 'plan',
      label: 'Plan',
      sortable: true,
      order: 7
    },
    {
      key: 'sex',
      label: 'Sex',
      sortable: true,
      order: 8
    },
    {
      key: 'dob',
      label: 'DOB',
      formatter: 'formatDate',
      sortable: true,
      order: 9
    },
    {
      key: 'patientStatus',
      label: 'Status',
      sortable: true,
      order: 10
    },
    {
      key: 'percentage_BFSM',
      label: '% Billing First Semester',
      formatter: (value: any) => {
        if (value) return (value * 100).toFixed(2) + '%'
      },
      sortable: true,
      order: 11
    },
    {
      key: 'percentage_BSSM',
      label: '% Billing Second Semester',
      formatter: (value: any) => {
        if (value == 0.0) return value
        if (value) return (value * 100).toFixed(2) + '%'
      },
      sortable: true,
      order: 12
    },
    {
      key: 'raF_TOTAL',
      label: 'RAF Total',
      formatter: (value: any) => {
        if (value) return value.toFixed(2)
      },
      sortable: true,
      order: 13
    },
    {
      key: 'recordID',
      label: 'Record ID',
      sortable: true,
      order: 14
    },
    {
      key: 'drRecordID',
      label: 'Dr. Record ID',
      sortable: true,
      order: 15
    },
    {
      key: 'recordDate',
      label: 'Record Date',
      formatter: 'formatDate',
      sortable: true,
      order: 16,
      format: 'd-mmm-yy'
    },
    {
      key: 'show_details',
      label: ''
    }
  ]
  public items: Patient[] = []
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public insurances: SelectVM[] = []
  public insurancesPlans: SelectVM[] = []
  public searchQuery = ''
  public contracts = [
    {
      text: '12345',
      value: 12345
    },
    {
      text: '125',
      value: 125
    }
  ]
  public userRole = ''
  public genders: SelectVM[] = []
  public form = {} as PatientVM
  public isEditing = false
  public currentUser = ''
  public showSearch = false
  public searchLabel = 'Patient Name, INS Contract No:'
  public selectedInsurance: SelectVM | null = null
  public canChangeStatus = true
  public sheets = [{ name: 'SheetOne', data: [{ c: 2 }] }]

  $refs!: {
    mytable: BTable
  }
  @Watch('perPage')
  perPageChange() {
    this._getPatients()
  }
  public async onPageChange(pageNumber: number) {
    await this._getPatients(pageNumber, this.searchQuery)
  }
  get _pagination() {
    return this.pagination
  }
  public async exportTable() {
    let items = []
    if (this.searchQuery || this.selectedInsurance) {
      const response = await getPatients(
        1,
        999999,
        this.searchQuery,
        this.selectedInsurance?.label || ''
      )
      items = response.data
    } else {
      const response = await getPatientsAll()
      items = response.data
    }

    // console.log(items)
    const updatedArray = items.map(obj => {
      const newObj = {}
      // Sort the first array based on the order property
      this.fields.sort((a, b) => a.order - b.order)
      // Get the keys in the order specified by the first array
      const keys = this.fields.map(x => x.key).filter(c => c != 'index')
      // Sort the keys of the object
      keys.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const label = this.fields.find(x => x.key === key).label
          newObj[label.toLowerCase()] = obj[key.toLowerCase()]
        }
      })
      return newObj
    })
    // console.log(updatedArray)
    const data = [
      {
        sheet: 'Patient List',
        columns: [],
        content: []
      }
    ]

    data[0].columns = this.fields
      .map(c => {
        return {
          label: c.label,
          value: c.key,
          format: c.format
        }
      })
      .filter(c => c.value != 'index')
    if (this.userRole === Role.Doctor) {
      data[0].columns = [
        { label: 'NPI', value: 'npi' },
        { label: 'Doctor Name', value: 'doctorFullName' }
      ].concat(data[0].columns)
      // data[0].columns.push()

      // this.fields = this.fields.map(c => {
      //   return {
      //     ...c,
      //     key: 'npi',
      //     label: 'NPI',
      //     order: 2,
      //     sortable: false
      //   }
      // })
    }
    data[0].content = items.map(c => {
      return {
        ...c,
        patientStatus: c.patientStatus ? 'Yes' : 'No',
        dob: this.formatDate(c.dob?.toString()),
        recordDate: this.formatDate(c.recordDate.toString()),
        ['raF_TOTAL']: c.raF_TOTAL?.toFixed(2),
        ['percentage_BFSM']: (c.percentage_BFSM * 100).toFixed(2) + '%',
        ['percentage_BSSM']: (c.percentage_BSSM * 100).toFixed(2) + '%'
        // npi: c.npi
      }
    })

    const settings = {
      fileName: 'Patient List', // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      RTL: false // Display the columns from right-to-left (the default value is false)
    }

    xlsx(data, settings) // Will download the excel file

    // const updatedArray = this.items.map(obj => {
    //   const newObj = {}
    //   for (const key in obj) {
    //     const label = this.fields.find(x => x.key === key)?.label
    //     newObj[label] = obj[key]
    //   }
    //   return newObj
    // })
    // const updatedArray = this.items.map(obj => {
    //   const newObj = {}
    //   // Sort the first array based on the order property
    //   this.fields.sort((a, b) => a.order - b.order)
    //   // Get the keys in the order specified by the first array
    //   const keys = this.fields.map(x => x.key)
    //   // Sort the keys of the object
    //   keys.forEach(key => {
    //     if (Object.prototype.hasOwnProperty.call(obj, key)) {
    //       const label = this.fields.find(x => x.key === key).label
    //       newObj[label] = obj[key]
    //     }
    //   })
    //   return newObj
    // })
    // console.log(updatedArray)

    // const data = updatedArray

    // },{}) //[{ foo: 'foo' }, { bar: 'bar' }]
    // const fileName = 'Patient_List'
    // const exportType = exportFromJSON.types.x

    // exportFromJSON({ data, fileName, exportType })
    // json2xlsx.writeFile(upnpm i json-as-xlsxdatedArray, 'example.xlsx')
  }
  public async getResolve() {
    const { insurances } = await getInsuranceAgreementsResolve()
    this.insuranceList = insurances
  }
  private async created() {
    this.currentUser = getCurrentUser()
    this.userRole = getUserRole()
    if (this.userRole === Role.Admin || this.userRole === Role.InsuranceStaff) {
      this.showSearch = true
      this.searchLabel = 'NPI, Doctor Name, Patient Name, INS Contract No:'
    } else {
      this.fields = this.fields.filter(
        i => i.key !== 'npi' && i.key !== 'doctorFullName'
      )
    }
    if (this.userRole === Role.InsuranceStaff) {
      this.canChangeStatus = false
    }
    // prepareExcelData()
    await this._getPatients()
    await this.getResolve()
  }
  public prepareExcelData() {
    this.sheets.push()
  }
  public async filter() {
    await this._getPatients(1, this.searchQuery)
  }
  public async cleanFilter() {
    this.searchQuery = ''
    this.selectedInsurance = null
    await this._getPatients(1, this.searchQuery)
  }
  private async _getPatients(pageNumber = 1, searchQuery = '') {
    const response = await getPatients(
      pageNumber,
      this.perPage,
      searchQuery,
      this.selectedInsurance?.label
    )
    this.items = response.data
    this.pagination = JSON.parse(response.headers['x-pagination'])
  }
  public formatDate(value: string) {
    if (value) return moment(value).format('MM/DD/YYYY')
  }
  public showRecord(recordID: string) {
    this.$router.push({ name: 'RecordsView', params: { recordID } })
  }

  public edit(item: PatientVM) {
    this.form = item
    // console.log(item)
  }
  public async create(): Promise<void> {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }

    const obj = {
      ...this.form,
      insuranceName: this.insurances.find(
        c => c.value == this.form.insuranceName
      )?.label,
      insurancePlan: this.insurancesPlans.find(
        c => c.value == this.form.insurancePlan
      )?.label,
      sex: this.genders.find(c => c.value == this.form.sex)?.label
    } as PatientVM
    if (!this.isEditing) {
      // console.log(obj)
      await postPatient(obj)
      // console.log(response)
      // if (response.some(c => c.error)) return
    } else {
      await updatePatient(obj)
    }
    // this.isEditing = false
    this._getPatients()

    this.$root.$emit('bv::toggle::modal', 'modal', '#btnCreate')

    // else {
    //   if (!this.form.insuranceID) return

    //   const obj: UpdateInsurance = {
    //     insuranceID: this.form.insuranceID,
    //     insuranceName: this.form.insuranceName
    //   }
    //   if (!obj.insuranceName) {
    //     this.$bvToast.toast('Please specify an insurance name', {
    //       title: 'Error!',
    //       variant: 'warning',
    //       solid: true,
    //       toaster: 'b-toaster-bottom-right',
    //       autoHideDelay: 5000,
    //       appendToast: true
    //     })
    //     return
    //   }
    //   const response = await editInsurance(obj)
    //   if (response.some(c => c.error)) return
    // }

    // this.isEditing = false
    // await this._getInsurances()
  }
  public changeStatus(checked: boolean, item: Patient) {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `This patient status will be set as ${
          checked ? 'Active' : 'Inactive'
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          const obj: UpdatePatientStatus = {
            doctorId: item.doctorId,
            documentID: item.documentID,
            statusValue: checked
          }

          updatePatientStatus(obj).then(() => {
            this._getPatients()
          })
          this.$swal.fire(
            `${checked ? 'Activated' : 'Deactivated'}!`,
            `The patient has been ${
              checked ? 'Activated' : 'Deactivated'
            } successfully`,
            'success'
          )
        } else {
          item.patientStatus = !item.patientStatus
        }
      })
  }
}
