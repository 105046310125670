import * as signalR from '@microsoft/signalr'
import store from '@/store'
import { Notification, NotificationMessage } from './models'
let connectionUrl = null
fetch(process.env.BASE_URL + 'config2.txt')
  .then(res => res.json())
  .then(data => {
    connectionUrl = data.SignalR
  })
class SignalRServiceClass {
  private connection: signalR.HubConnection

  constructor(userId: string) {
    const urlWithUserId = `${connectionUrl}/notify?userId=${userId}`
    // console.log(urlWithUserId)
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(urlWithUserId)
      .configureLogging(signalR.LogLevel.Information)
      .build()

    this.connection.on('ReceiveMessage', message => {
      store.dispatch('app/addMessage', message)
      // Assuming you have set up a mutation for setting toast message
      store.commit('app/setToastMessage', message)
    })
  }
  public async sendMessage(payload: NotificationMessage): Promise<void> {
    try {
      if (payload.ByRole) {
        await this.connection.invoke('SendToRole', payload)
      } else {
        await this.connection.invoke('SendMessageToUser', payload)
      }
    } catch (err) {
      console.error('Error sending message:', err)
    }
  }
  public async startConnection(): Promise<void> {
    try {
      await this.connection.start()
      console.log('connected')
    } catch (err) {
      console.log(err)
      setTimeout(() => this.startConnection(), 5000)
    }
  }
  public async stopConnection(): Promise<void> {
    try {
      console.log(this.connection)
      await this.connection.stop()
      console.log('Connection stopped')
    } catch (err) {
      console.error('Error stopping connection:', err)
    }
  }

  public getConnection(): signalR.HubConnection {
    return this.connection
  }
}

// export default new SignalRService()
export default SignalRServiceClass

// You can create an instance separately if needed
// export const SignalRService = new SignalRServiceClass()
