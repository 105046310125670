import {
  Insurance,
  InsuranceAgreementsResolve,
  InsuranceAgreementsVM,
  InsurancePostResult,
  RmtInsuranceAgreement,
  UpdateInsurance,
  UpdateInsuranceStatus,
  UpdateInsuranceAgreement,
  SpResult,
  Role
} from '@/models'
import axios, { AxiosResponse } from 'axios'
const API_URL = 'Insurances'
import { getUserRole } from '@/components/admin/users/users.service'

export const getInsurances = async (
  pageNumber = 1,
  pageSize = 10
): Promise<AxiosResponse<Insurance[]>> => {
  const response = await axios.get(
    API_URL +
      `/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}&getCanceled=false`
  )
  return response || null
}

export const getInsuranceAgreements = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = '',
  doctorId: string | null = null
): Promise<AxiosResponse<RmtInsuranceAgreement[]>> => {
  let params = ''

  if (!doctorId) {
    params = `pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  } else {
    params += `&doctorId=${doctorId}`
  }

  const response = await axios.get(
    API_URL + `/GetInsuranceAgreements?${params}`
  )
  return response || null
}

export const getGeneralReport = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
) => {
  let doctorId: string | null = null
  if (getUserRole() == Role.Staff) {
    doctorId = localStorage.getItem('selectedDoctor')
  }
  let url = `/GetGeneralReport?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  if (doctorId) {
    url += `&doctorId=${doctorId}`
  }
  const response = await axios.get(API_URL + url)
  return response || null
}

export const getBFSReport = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
) => {
  let doctorId: string | null = null
  if (getUserRole() == Role.Staff) {
    doctorId = localStorage.getItem('selectedDoctor')
  }
  let url = `/GetBFSReport?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  if (doctorId) {
    url += `&doctorId=${doctorId}`
  }
  const response = await axios.get(API_URL + url)
  return response || null
}
export const getBSSReport = async (
  pageNumber = 1,
  pageSize = 10,
  searchQuery = ''
) => {
  let doctorId: string | null = null
  if (getUserRole() == Role.Staff) {
    doctorId = localStorage.getItem('selectedDoctor')
  }
  let url = `/getBSSReport?pageNumber=${pageNumber}&pageSize=${pageSize}&SearchQuery=${searchQuery}`
  if (doctorId) {
    url += `&doctorId=${doctorId}`
  }
  const response = await axios.get(API_URL + url)
  return response || null
}
export const getInsuranceAgreementsResolve = async (): Promise<InsuranceAgreementsResolve> => {
  const { data } = await axios.get(API_URL + `/InsuranceAgreementResolve`)
  return data
}
export const postInsurance = async (model: Insurance): Promise<SpResult[]> => {
  const { data } = await axios.post(API_URL, model)
  return data
}
export const postInsuranceAgreement = async (
  model: InsuranceAgreementsVM
): Promise<InsurancePostResult> => {
  const { data } = await axios.post(
    API_URL + `/CreateInsuranceAgreement`,
    model
  )
  return data
}
export const updateInsuranceStatus = async (
  model: UpdateInsuranceStatus
): Promise<UpdateInsuranceStatus[]> => {
  const { data } = await axios.put<UpdateInsuranceStatus[]>(
    API_URL + `/UpdateInsuranceStatus`,
    model
  )
  return data
}
export const editInsurance = async (
  model: UpdateInsurance
): Promise<SpResult[]> => {
  const { data } = await axios.put<SpResult[]>(
    API_URL + `/UpdateInsurance`,
    model
  )
  return data
}
export const updateInsuranceAgreement = async (
  model: UpdateInsuranceAgreement
): Promise<UpdateInsuranceAgreement[]> => {
  const { data } = await axios.put<UpdateInsuranceAgreement[]>(
    API_URL + `/UpdateInsuranceAgreement`,
    model
  )
  return data
}
export default {
  getInsurances,
  postInsurance,
  updateInsuranceStatus,
  editInsurance,
  getInsuranceAgreements,
  getInsuranceAgreementsResolve,
  postInsuranceAgreement,
  updateInsuranceAgreement,
  getBSSReport,
  getBFSReport,
  getGeneralReport
}
