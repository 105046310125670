
import { Component, Vue } from 'vue-property-decorator'
import BEditableTable from 'bootstrap-vue-editable-table'
import { Diagnostic, RecordDiagnostic, Role } from '@/models/index'
import { getInsurances } from '@/components/insurances/insurance.service'
import { Debounce } from 'typescript-debounce'
import {
  createDiagnostic,
  getDiagnostics,
  updateDiagnostic
} from '@/components/diagnostics/diagnostics.service'
import Multiselect from 'vue-multiselect'
import { getUserRole } from '@/components/admin/users/users.service'
@Component({ components: { Multiselect, BEditableTable } })
export default class DiagnosticsView extends Vue {
  public value: Diagnostic[] = []
  public options: Diagnostic[] = []
  public items: Diagnostic[] = []
  public form = {} as Diagnostic
  public rowUpdate = {}
  public isEditing = false
  public showEditButton = false
  public fields = [
    {
      key: 'diagnosticId',
      label: 'Code'
    },
    {
      key: 'descriptions',
      label: 'Description',
      tdClass: 'align-middle',
      editable: true,
      type: 'text'
    },
    {
      key: 'cmshcc1',
      label: 'CMSHcc1',
      tdClass: 'align-middle',
      editable: true
    },
    {
      key: 'cmshcc2',
      label: 'CMSHcc2',
      tdClass: 'align-middle',
      editable: true,
      type: 'number'
    },
    {
      key: 'cmshcc3',
      label: 'CMSHcc3',
      tdClass: 'align-middle',
      editable: true,
      type: 'number'
    },
    {
      key: 'rxHcc1',
      label: 'RxHcc1',
      tdClass: 'align-middle',
      editable: true,
      type: 'number'
    },
    {
      key: 'rxHcc2',
      label: 'RxHcc2',
      tdClass: 'align-middle',
      editable: true,
      type: 'number'
    },
    {
      key: 'idinteraction',
      label: 'Interaction',
      tdClass: 'align-middle',
      editable: true,
      type: 'number'
    },
    {
      key: 'alert',
      label: 'Alert',
      tdClass: 'align-middle',
      editable: true
    },
    {
      key: 'alertDelete',
      label: 'Delete',
      tdClass: 'align-middle',
      editable: true
    },
    {
      key: 'documentation',
      label: 'Documentation',
      tdClass: 'align-middle',
      editable: true,
      type: 'text'
    },
    {
      key: 'crosswalkDx',
      label: 'CrosswalkDx',
      tdClass: 'align-middle',
      editable: true
    },
    {
      key: 'crosswalkRx',
      label: 'CrosswalkRx',
      tdClass: 'align-middle',
      editable: true
    },
    {
      key: 'diagnosticStatus',
      label: 'Status',
      tdClass: 'align-middle'
    },
    // {
    //   key: "idInteraction",
    //   label: "ID Interaction",
    // },

    // {
    //   key: 'actions',
    //   label: 'Active',
    //   tdClass: 'align-middle'
    // },
    {
      key: 'actions',
      label: '',
      tdClass: 'align-middle'
    }
  ]

  public handleEdit(data: any) {
    this.isEditing = !this.isEditing
    this.rowUpdate = { edit: this.isEditing, id: data.id }
  }
  public async saveChanges() {
    this.isEditing = false
    await updateDiagnostic(this.items[0])
  }
  public handleSubmit(data) {
    this.isEditing = false

    const o = { ...this.items[0] }
    o.cmshcc1 = +data.item.cmshcc1
    this.rowUpdate = {
      edit: false,
      id: data.id,
      action: 'update'
    }

    updateDiagnostic(o)
    // console.log(this.rowUpdate)
  }
  public async created() {
    this._getDiagnostics(this.value)
    if (getUserRole() == Role.Admin) {
      this.showEditButton = true
    }
  }
  public onSelected(e: Diagnostic) {
    this.items = []
    this.items.push(e)
  }

  public customLabel({
    diagnosticId,
    descriptions
  }: {
    diagnosticId: string
    descriptions: string
  }): string {
    return `${diagnosticId} - ${descriptions}`
  }
  @Debounce({ millisecondsDelay: 1000 })
  public _getDiagnostics(value: any): void {
    // if (value.length >= 3) {
    getDiagnostics(1, 10, value).then(r => {
      this.options = r.data
    })
    // }
  }
  public async create() {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }
    const response = await createDiagnostic(this.form)
    // console.log(response)
    // const response = await postInsurance(this.form)
    // console.log(response)
    // if (response === 204) {
    //   console.log(11);
    //   this.items.push(this.form);
    // }
  }
}
